import { useDrag } from 'react-dnd';

const DraggableExercise = ({ exercise, cardType }: any) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'EXERCISE',
        item: { ...exercise,cardType },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            className="flex align-items-center justify-content-between m-2 p-2"
            style={{
                backgroundColor: "white",
                borderRadius: "12px",
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
            }}
        >
            <div className="flex gap-4 align-items-center">
                <img src={exercise.image} width={50} height={50} style={{ objectFit: "contain", backgroundColor: "#EBEDF2", borderRadius: "10px" }} />
                <p>{exercise.name}</p>
            </div>
            <img src="/dragIcon.svg" />
        </div>
    );
};

export default DraggableExercise;