import { Button } from "primereact/button"
import ExerciseCard from "./exerciseCard"
import ExerciseDetails from "./exerciseDetails"
import { useState } from "react"

const ExerciseDetailsView = ({ t, setSeeAllPlans } : any) => {
    const [exercises, setExercises] = useState<any>([
        {
            name: "Arm Exercise",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15,
            periods: ["Morning", "Afternoon", "Evening"],
            days: ["Mon", "Tue", "Wed", "Thu", "Fri"]
        },
    ])
    const [selectedExercise, setSelectedExercise] = useState<any>()

    return (
        <div className='flex flex-column gap-4'>
            <Button className='w-20rem gap-2' text severity='secondary' onClick={() => setSeeAllPlans(true)}>
                <img src="/Arrow - Left.svg" />
                <span className="text-lg font-semibold">Neck Pain Reducing Plan</span>
            </Button>
            <ExerciseCard t={t} exercises={exercises} selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} />
            {selectedExercise && <ExerciseDetails t={t} selectedExercise={selectedExercise} />}
        </div>
    )
}

export default ExerciseDetailsView