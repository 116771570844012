import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button";

const LibraryAccordion = ({ myPanels, setMyPanel, exerciseProcedureList, setExerciseVideoDialogVisible, setSelectedExercise }: any) => {

    const deletePanel = (currentPanel: any, e: any) => {
        setMyPanel((prevPanels: any) => prevPanels.filter((panel: any) => panel.id !== currentPanel.id));
        e.stopPropagation();
    }


    const headeContent = (panel: any) => {
        return (
            <div
                className="flex align-items-center justify-content-between m-2 pr-2"
                style={{
                    height: "2rem"
                }}
            >
                <div className="flex gap-4 align-items-center">
                    <img src="/panelIcon.svg" style={{ backgroundColor: "#EBEDF2", borderRadius: "10px" }} />
                    <p>{panel.name}</p>
                </div>
                <Button
                    icon={<img src="/redTrashIcon.svg" />}
                    onClick={(e) => deletePanel(panel, e)}
                    style={{
                        border: "none",
                        backgroundColor: "#FCEEF0",
                        borderRadius: "10px",
                        width: "2rem",
                        height: "2rem"
                    }} />

            </div>
        )
    }

    const exerciseDivs = (panelExerciseList: any) => {
        const filteredExerciseList = exerciseProcedureList.filter((exercise: any) => panelExerciseList.exercises.includes(exercise.id))

        return filteredExerciseList.map((exercise: any,index:any) => {
            return (
                <div className="flex flex-column">
                    <div
                        className="flex align-items-center justify-content-between my-1 mx-2 p-2 pr-5" style={{ borderRadius: "10px", }}>
                        <div className="flex gap-4 align-items-center">
                            <img src={exercise.image} width={50} height={50} style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }} />
                            <p className="font-bold text-md" style={{ color: "#2D3967" }}>{exercise.name}</p>
                        </div>
                        <Button
                            icon={<img src="/playIcon.svg" width={20} height={20} />}
                            onClick={() => { setExerciseVideoDialogVisible(true); setSelectedExercise(exercise) }}
                            style={{
                                border: "none",
                                backgroundColor: "rgba(227, 80, 107, 0.9)",
                                borderRadius: "10px",
                                width: "2rem",
                                height: "2rem"
                            }} />

                    </div>
                    {index !== filteredExerciseList.length-1 && <div style={{border:"1px solid #e5e7eb"}}></div>} 
                </div>
            )

        })

    }

    const createDynamicTabs = () => {
        return myPanels.map((panel: any) => {
            return (
                <AccordionTab key={panel.id} header={() => headeContent(panel)}>
                    {exerciseDivs(panel)}
                </AccordionTab>
            );
        });
    };


    return (
        <div className="w-full libraryAccordion" >
            <Accordion>{createDynamicTabs()}</Accordion>
        </div>
    )
}

export default LibraryAccordion