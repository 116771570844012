import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";

import DropArea from "./dropArea";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

const CreatePanelDialog = ({ createPanelDialogVisible, setCreatePanelDialogVisible, systemPanels, exerciseProcedureList, setMyPanel, t }: any) => {
    const [selectedPanel, setSelectedPanel] = useState<any>();
    const [allExercises, setAllExercises] = useState<any>();
    const [selectedExercises, setSelectedExercises] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [panelName, setPanelName] = useState("");

    const [panelNameIsNotGiven, setPanelNameIsNotGiven] = useState(false);
    const [exerciseIsNotGiven, setExerciseIsNotGiven] = useState(false);

    useEffect(() => {
        setAllExercises(exerciseProcedureList);
    }, [createPanelDialogVisible]);

    const notSelectedexercises = (filteredExercises: any) => {
        const selectedExerciseIds = new Set(selectedExercises.map((exercise: any) => exercise.id));
        return filteredExercises.filter((exercise: any) => !selectedExerciseIds.has(exercise.id));
    }

    const onPanelChange = (value: any) => {
        setSearchTerm('');
        if (value) {
            const filteredExercises = exerciseProcedureList.filter((exercise: any) => value.exercises.includes(exercise.id));

            setAllExercises(notSelectedexercises(filteredExercises));
            setSelectedPanel(value)
        }

        else {
            setAllExercises(notSelectedexercises(exerciseProcedureList));
            setSelectedPanel(value)
        }
    }

    const clearAll = () => {
        if (selectedPanel) {
            const filteredExercises = exerciseProcedureList.filter((exercise: any) => selectedPanel.exercises.includes(exercise.id));
            setAllExercises(filteredExercises);
        }
        else {
            setAllExercises(exerciseProcedureList);
        }
        setSelectedExercises([])
        setSearchTerm('');
    }

    const onDialogClose = () => {
        setCreatePanelDialogVisible(false)
        setSelectedPanel(null);
        setSelectedExercises([]);
        setSearchTerm('');
        setPanelName('')
        setPanelNameIsNotGiven(false)
        setExerciseIsNotGiven(false)
    }

    const onSearchInputChange = (e: any) => {
        setSearchTerm(e.target.value);

        const filtered = exerciseProcedureList.filter((exercise: any) =>
            exercise.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );

        setAllExercises(filtered);
    };

    const onDialogSave = () => {
        let escape = false
        if (!panelName) {
            escape = true;
            setPanelNameIsNotGiven(true)
        }

        if (selectedExercises.length === 0) {
            escape = true;
            setExerciseIsNotGiven(true)
        }

        if (escape) {
            return
        }

        const exerciseListIds = []
        for (const exercise of selectedExercises) {
            exerciseListIds.push(exercise.id)
        }

        const newPanel = {
            id: 7,
            name: panelName,
            exercises: exerciseListIds
        }

        setMyPanel((prevPanel: any) => prevPanel ? [...prevPanel, newPanel] : [newPanel]);
        onDialogClose();

    }

    const headerContent = (
        <div className="flex flex-wrap justify-content-between align-items-center gap-2 mr-2">
            <p className="text-xl" style={{ color: "#727A99" }}>{t("selectExercise")}</p>
            <div className="flex gap-2 flex-wrap">
                <Dropdown
                    showClear
                    value={selectedPanel}
                    onChange={(e) => onPanelChange(e.value)}
                    options={systemPanels}
                    optionLabel="name"
                    placeholder={t("systemPanels")}
                    className="w-20rem"
                    style={{ borderRadius: "10px" }}

                />
                <IconField iconPosition="left" className="w-20rem">
                    <InputIcon className="pi pi-search" />
                    <InputText disabled={selectedPanel} value={searchTerm} className="w-full" style={{ borderRadius: "10px" }} placeholder={t("datatableSearchPlaceholderForLibrary")} onChange={onSearchInputChange} />
                </IconField>
            </div>
        </div>
    )

    const footerContent = (
        <div className="flex justify-content-between gap-2">
            <Button
                onClick={clearAll}
                style={{
                    backgroundColor: "#BEC2D0",
                    width: "14rem",
                    height: "3rem",
                    borderRadius: "10px",
                    color: "white",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                }}
            >
                <img src="/trashicon.svg" />
                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("clearAll")}</p>
            </Button>
            <div className="flex justify-content-end gap-2">
                <InputText value={panelName}
                    style={{ borderRadius: "10px" }}
                    className={panelNameIsNotGiven ? panelName ? undefined : "p-invalid mr-2" : undefined}
                    onChange={e => setPanelName(e.target.value)}
                    placeholder={t("enterPanelName")} />

                <Button
                    onClick={onDialogSave}
                    style={{
                        backgroundColor: "#E3506B",
                        width: "14rem",
                        height: "3rem",
                        borderRadius: "10px",
                        color: "white",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem"
                    }}
                >
                    <img src="/accept.svg" />
                    <p className="m-0 font-bold" style={{ margin: 0 }}>{t("savePanel")}</p>
                </Button>
            </div>
        </div>

    );

    const onDrop = (item: any, cardType: any) => {
        if (cardType === 'selectedExercises' && item.cardType !== 'selectedExercises') {
            setAllExercises((prev: any) => prev.filter((ex: any) => ex.id !== item.id));
            setSelectedExercises((prev: any): any => [...prev, item]);
        } else if (cardType === 'allExercises' && item.cardType !== 'allExercises') {
            setSelectedExercises((prev: any) => prev.filter((ex: any) => ex.id !== item.id));
            setAllExercises((prev: any): any => [item, ...prev]);
        }
    };

    return (
        <Dialog
            footer={footerContent}
            header={headerContent}
            visible={createPanelDialogVisible}
            style={{ width: '80vw', borderRadius: '24px', overflow: "hidden" }}
            onHide={onDialogClose}
        >
            <div className="grid gap-2">
                <div className="col libraryCards">
                    <p className="ml-2 text-lg font-bold" style={{ color: "#2D3967" }}>{t('allExercises')}</p>
                    <Card style={{
                        borderRadius: "24px", position: "relative", backgroundColor: "#F5F5F5",

                    }}>
                        <DropArea
                            cardType="allExercises"
                            exercises={allExercises}
                            onDrop={onDrop}
                            selectedExercises={selectedExercises}
                            exerciseIsNotGiven={exerciseIsNotGiven} />
                    </Card>
                </div>
                <div className="col libraryCards">
                    <p className="ml-2 text-lg font-bold" style={{ color: "#2D3967" }}>{t('selectedExercises')}</p>
                    <Card style={{ borderRadius: "24px", position: "relative", backgroundColor: "#F5F5F5" }}>
                        <DropArea
                            cardType="selectedExercises"
                            exercises={selectedExercises}
                            onDrop={onDrop}
                            selectedExercises={selectedExercises}
                            exerciseIsNotGiven={exerciseIsNotGiven} />
                    </Card>
                </div>
            </div>
        </Dialog>
    );
};

export default CreatePanelDialog;
