export const countries = [
    { name: 'afghanistan', code: 'AF', phoneCode: '+93' },
    { name: 'albania', code: 'AL', phoneCode: '+355' },
    { name: 'algeria', code: 'DZ', phoneCode: '+213' },
    { name: 'andorra', code: 'AD', phoneCode: '+376' },
    { name: 'angola', code: 'AO', phoneCode: '+244' },
    { name: 'antigua And Barbuda', code: 'AG', phoneCode: '+1-268' },
    { name: 'argentina', code: 'AR', phoneCode: '+54' },
    { name: 'armenia', code: 'AM', phoneCode: '+374' },
    { name: 'australia', code: 'AU', phoneCode: '+61' },
    { name: 'austria', code: 'AT', phoneCode: '+43' },
    { name: 'azerbaijan', code: 'AZ', phoneCode: '+994' },
    { name: 'bahamas', code: 'BS', phoneCode: '+1-242' },
    { name: 'bahrain', code: 'BH', phoneCode: '+973' },
    { name: 'bangladesh', code: 'BD', phoneCode: '+880' },
    { name: 'barbados', code: 'BB', phoneCode: '+1-246' },
    { name: 'belarus', code: 'BY', phoneCode: '+375' },
    { name: 'belgium', code: 'BE', phoneCode: '+32' },
    { name: 'belize', code: 'BZ', phoneCode: '+501' },
    { name: 'benin', code: 'BJ', phoneCode: '+229' },
    { name: 'bhutan', code: 'BT', phoneCode: '+975' },
    { name: 'bolivia', code: 'BO', phoneCode: '+591' },
    { name: 'bosnia And Herzegovina', code: 'BA', phoneCode: '+387' },
    { name: 'botswana', code: 'BW', phoneCode: '+267' },
    { name: 'brazil', code: 'BR', phoneCode: '+55' },
    { name: 'brunei Darussalam', code: 'BN', phoneCode: '+673' },
    { name: 'bulgaria', code: 'BG', phoneCode: '+359' },
    { name: 'burkina Faso', code: 'BF', phoneCode: '+226' },
    { name: 'burundi', code: 'BI', phoneCode: '+257' },
    { name: 'cabo Verde', code: 'CV', phoneCode: '+238' },
    { name: 'cambodia', code: 'KH', phoneCode: '+855' },
    { name: 'cameroon', code: 'CM', phoneCode: '+237' },
    { name: 'canada', code: 'CA', phoneCode: '+1' },
    { name: 'central African Republic', code: 'CF', phoneCode: '+236' },
    { name: 'chad', code: 'TD', phoneCode: '+235' },
    { name: 'chile', code: 'CL', phoneCode: '+56' },
    { name: 'china', code: 'CN', phoneCode: '+86' },
    { name: 'colombia', code: 'CO', phoneCode: '+57' },
    { name: 'comoros', code: 'KM', phoneCode: '+269' },
    { name: 'congo', code: 'CG', phoneCode: '+242' },
    { name: 'costa Rica', code: 'CR', phoneCode: '+506' },
    { name: 'croatia', code: 'HR', phoneCode: '+385' },
    { name: 'cuba', code: 'CU', phoneCode: '+53' },
    { name: 'cyprus', code: 'CY', phoneCode: '+357' },
    { name: 'czech Republic', code: 'CZ', phoneCode: '+420' },
    { name: 'democratic Republic Of The Congo', code: 'CD', phoneCode: '+243' },
    { name: 'denmark', code: 'DK', phoneCode: '+45' },
    { name: 'djibouti', code: 'DJ', phoneCode: '+253' },
    { name: 'dominica', code: 'DM', phoneCode: '+1-767' },
    { name: 'dominican Republic', code: 'DO', phoneCode: '+1-809' },
    { name: 'ecuador', code: 'EC', phoneCode: '+593' },
    { name: 'egypt', code: 'EG', phoneCode: '+20' },
    { name: 'el Salvador', code: 'SV', phoneCode: '+503' },
    { name: 'equatorial Guinea', code: 'GQ', phoneCode: '+240' },
    { name: 'eritrea', code: 'ER', phoneCode: '+291' },
    { name: 'estonia', code: 'EE', phoneCode: '+372' },
    { name: 'eswatini', code: 'SZ', phoneCode: '+268' },
    { name: 'ethiopia', code: 'ET', phoneCode: '+251' },
    { name: 'fiji', code: 'FJ', phoneCode: '+679' },
    { name: 'finland', code: 'FI', phoneCode: '+358' },
    { name: 'france', code: 'FR', phoneCode: '+33' },
    { name: 'gabon', code: 'GA', phoneCode: '+241' },
    { name: 'gambia', code: 'GM', phoneCode: '+220' },
    { name: 'georgia', code: 'GE', phoneCode: '+995' },
    { name: 'germany', code: 'DE', phoneCode: '+49' },
    { name: 'ghana', code: 'GH', phoneCode: '+233' },
    { name: 'greece', code: 'GR', phoneCode: '+30' },
    { name: 'grenada', code: 'GD', phoneCode: '+1-473' },
    { name: 'guatemala', code: 'GT', phoneCode: '+502' },
    { name: 'guinea', code: 'GN', phoneCode: '+224' },
    { name: 'guinea Bissau', code: 'GW', phoneCode: '+245' },
    { name: 'guyana', code: 'GY', phoneCode: '+592' },
    { name: 'haiti', code: 'HT', phoneCode: '+509' },
    { name: 'honduras', code: 'HN', phoneCode: '+504' },
    { name: 'hungary', code: 'HU', phoneCode: '+36' },
    { name: 'iceland', code: 'IS', phoneCode: '+354' },
    { name: 'india', code: 'IN', phoneCode: '+91' },
    { name: 'indonesia', code: 'ID', phoneCode: '+62' },
    { name: 'iran', code: 'IR', phoneCode: '+98' },
    { name: 'iraq', code: 'IQ', phoneCode: '+964' },
    { name: 'ireland', code: 'IE', phoneCode: '+353' },
    { name: 'israel', code: 'IL', phoneCode: '+972' },
    { name: 'italy', code: 'IT', phoneCode: '+39' },
    { name: 'jamaica', code: 'JM', phoneCode: '+1-876' },
    { name: 'japan', code: 'JP', phoneCode: '+81' },
    { name: 'jordan', code: 'JO', phoneCode: '+962' },
    { name: 'kazakhstan', code: 'KZ', phoneCode: '+7' },
    { name: 'kenya', code: 'KE', phoneCode: '+254' },
    { name: 'kiribati', code: 'KI', phoneCode: '+686' },
    { name: 'kuwait', code: 'KW', phoneCode: '+965' },
    { name: 'kyrgyzstan', code: 'KG', phoneCode: '+996' },
    { name: 'laos', code: 'LA', phoneCode: '+856' },
    { name: 'latvia', code: 'LV', phoneCode: '+371' },
    { name: 'lebanon', code: 'LB', phoneCode: '+961' },
    { name: 'lesotho', code: 'LS', phoneCode: '+266' },
    { name: 'liberia', code: 'LR', phoneCode: '+231' },
    { name: 'libya', code: 'LY', phoneCode: '+218' },
    { name: 'liechtenstein', code: 'LI', phoneCode: '+423' },
    { name: 'lithuania', code: 'LT', phoneCode: '+370' },
    { name: 'luxembourg', code: 'LU', phoneCode: '+352' },
    { name: 'madagascar', code: 'MG', phoneCode: '+261' },
    { name: 'malawi', code: 'MW', phoneCode: '+265' },
    { name: 'malaysia', code: 'MY', phoneCode: '+60' },
    { name: 'maldives', code: 'MV', phoneCode: '+960' },
    { name: 'mali', code: 'ML', phoneCode: '+223' },
    { name: 'malta', code: 'MT', phoneCode: '+356' },
    { name: 'marshall Islands', code: 'MH', phoneCode: '+692' },
    { name: 'mauritania', code: 'MR', phoneCode: '+222' },
    { name: 'mauritius', code: 'MU', phoneCode: '+230' },
    { name: 'mexico', code: 'MX', phoneCode: '+52' },
    { name: 'micronesia', code: 'FM', phoneCode: '+691' },
    { name: 'moldova', code: 'MD', phoneCode: '+373' },
    { name: 'monaco', code: 'MC', phoneCode: '+377' },
    { name: 'mongolia', code: 'MN', phoneCode: '+976' },
    { name: 'montenegro', code: 'ME', phoneCode: '+382' },
    { name: 'morocco', code: 'MA', phoneCode: '+212' },
    { name: 'mozambique', code: 'MZ', phoneCode: '+258' },
    { name: 'myanmar', code: 'MM', phoneCode: '+95' },
    { name: 'namibia', code: 'NA', phoneCode: '+264' },
    { name: 'nauru', code: 'NR', phoneCode: '+674' },
    { name: 'nepal', code: 'NP', phoneCode: '+977' },
    { name: 'netherlands', code: 'NL', phoneCode: '+31' },
    { name: 'new Zealand', code: 'NZ', phoneCode: '+64' },
    { name: 'nicaragua', code: 'NI', phoneCode: '+505' },
    { name: 'niger', code: 'NE', phoneCode: '+227' },
    { name: 'nigeria', code: 'NG', phoneCode: '+234' },
    { name: 'north Macedonia', code: 'MK', phoneCode: '+389' },
    { name: 'norway', code: 'NO', phoneCode: '+47' },
    { name: 'oman', code: 'OM', phoneCode: '+968' },
    { name: 'pakistan', code: 'PK', phoneCode: '+92' },
    { name: 'palau', code: 'PW', phoneCode: '+680' },
    { name: 'panama', code: 'PA', phoneCode: '+507' },
    { name: 'papua New Guinea', code: 'PG', phoneCode: '+675' },
    { name: 'paraguay', code: 'PY', phoneCode: '+595' },
    { name: 'peru', code: 'PE', phoneCode: '+51' },
    { name: 'philippines', code: 'PH', phoneCode: '+63' },
    { name: 'poland', code: 'PL', phoneCode: '+48' },
    { name: 'portugal', code: 'PT', phoneCode: '+351' },
    { name: 'qatar', code: 'QA', phoneCode: '+974' },
    { name: 'korea', code: 'KR', phoneCode: '+82' },
    { name: 'romania', code: 'RO', phoneCode: '+40' },
    { name: 'russian Federation', code: 'RU', phoneCode: '+7' },
    { name: 'rwanda', code: 'RW', phoneCode: '+250' },
    { name: 'saint Kitts And Nevis', code: 'KN', phoneCode: '+1-869' },
    { name: 'saint Lucia', code: 'LC', phoneCode: '+1-758' },
    { name: 'saint Vincent And The Grenadines', code: 'VC', phoneCode: '+1-784' },
    { name: 'samoa', code: 'WS', phoneCode: '+685' },
    { name: 'san Marino', code: 'SM', phoneCode: '+378' },
    { name: 'sao Tome And Principe', code: 'ST', phoneCode: '+239' },
    { name: 'saudi Arabia', code: 'SA', phoneCode: '+966' },
    { name: 'senegal', code: 'SN', phoneCode: '+221' },
    { name: 'serbia', code: 'RS', phoneCode: '+381' },
    { name: 'singapore', code: 'SG', phoneCode: '+65' },
    { name: 'slovakia', code: 'SK', phoneCode: '+421' },
    { name: 'slovenia', code: 'SI', phoneCode: '+386' },
    { name: 'solomon Islands', code: 'SB', phoneCode: '+677' },
    { name: 'somalia', code: 'SO', phoneCode: '+252' },
    { name: 'south Africa', code: 'ZA', phoneCode: '+27' },
    { name: 'south Sudan', code: 'SS', phoneCode: '+211' },
    { name: 'spain', code: 'ES', phoneCode: '+34' },
    { name: 'sri Lanka', code: 'LK', phoneCode: '+94' },
    { name: 'palestine', code: 'PS', phoneCode: '+970' },
    { name: 'sudan', code: 'SD', phoneCode: '+249' },
    { name: 'suriname', code: 'SR', phoneCode: '+597' },
    { name: 'sweden', code: 'SE', phoneCode: '+46' },
    { name: 'switzerland', code: 'CH', phoneCode: '+41' },
    { name: 'syria', code: 'SY', phoneCode: '+963' },
    { name: 'tajikistan', code: 'TJ', phoneCode: '+992' },
    { name: 'thailand', code: 'TH', phoneCode: '+66' },
    { name: 'togo', code: 'TG', phoneCode: '+228' },
    { name: 'tonga', code: 'TO', phoneCode: '+676' },
    { name: 'trinidad And Tobago', code: 'TT', phoneCode: '+1-868' },
    { name: 'tunisia', code: 'TN', phoneCode: '+216' },
    { name: 'turkey', code: 'TR', phoneCode: '+90' },
    { name: 'turkmenistan', code: 'TM', phoneCode: '+993' },
    { name: 'tuvalu', code: 'TV', phoneCode: '+688' },
    { name: 'uganda', code: 'UG', phoneCode: '+256' },
    { name: 'ukraine', code: 'UA', phoneCode: '+380' },
    { name: 'united Arab Emirates', code: 'AE', phoneCode: '+971' },
    { name: 'united Kingdom', code: 'GB', phoneCode: '+44' },
    { name: 'united States Of America', code: 'US', phoneCode: '+1' },
    { name: 'uruguay', code: 'UY', phoneCode: '+598' },
    { name: 'uzbekistan', code: 'UZ', phoneCode: '+998' },
    { name: 'vanuatu', code: 'VU', phoneCode: '+678' },
    { name: 'venezuela', code: 'VE', phoneCode: '+58' },
    { name: 'vietnam', code: 'VN', phoneCode: '+84' },
    { name: 'yemen', code: 'YE', phoneCode: '+967' },
    { name: 'zambia', code: 'ZM', phoneCode: '+260' },
    { name: 'zimbabwe', code: 'ZW', phoneCode: '+263' }
];