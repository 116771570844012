import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const CompleteChart = ({ t,empty }: any) => {
    const chartRef: any = useRef(null);

    const getGradient = (ctx: any) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "#2D3967");
        gradient.addColorStop(0.3, "#E3506B");
        return gradient;
    };

    const value = empty?0:68;

    const data = {
        datasets: [
            {
                data: [value, 100 - value],
                backgroundColor: chartRef.current
                    ? [getGradient(chartRef.current.ctx), "#EBEDF2"]
                    : ["#2D3967", "#EBEDF2"],
                borderWidth: 3,
                borderRadius: 50,
                cutout: "80%",
            },
        ],
    };

    const options: any = {
        rotation: 180,
        circumference: 360,
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: null,
        },
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    return (
        <div style={{ position: "relative", width: "8rem", height: "8rem" }}>
            <Doughnut ref={chartRef} data={data} options={options} />
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                }}
            >
                <span style={{ fontSize: "30px", fontWeight: "bold", color: "#2D3967" }}>{value === 0 ? '00' : 68} <span style={{ color: "#9EA4B9", fontSize: "15px" }}>%</span></span>
                <br />
                <span style={{ fontSize: "12px", color: "#9EA4B9" }}>{t("complete")}</span>
            </div>
        </div>
    );
};

export default CompleteChart;