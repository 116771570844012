import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";


import Login from "./Login/login";
import Home from "./Home/home";
import GlobalLayout from "./GlobalLayout/globalLayout";
import Appointment from "./Appointment/appointment";
import Patients from "./Patients/patients";
import Prescription from "./Prescription/prescription";
import Library from "./Library/library";
import ConsultantProfile from "./ConsultantProfile/consultantProfile";
import Download from "./download";


export default () => {

    return (
        <BrowserRouter basename={""}>
            <Routes>
                <Route path="/" element={<Login />}></Route>
                <Route path="/mobithera" element={<GlobalLayout />}>
                    <Route index path="/mobithera/home" element={<Home />}></Route>
                    <Route index path="/mobithera/appointment" element={<Appointment />}></Route>
                    <Route index path="/mobithera/patients" element={<Patients />}></Route>
                    <Route index path="/mobithera/library" element={<Library />}></Route>
                    <Route index path="/mobithera/prescription" element={<Prescription />}></Route>
                    <Route index path="/mobithera/consultantProfile" element={<ConsultantProfile />}></Route>
                </Route>
                <Route path="/download" element={<Download />}></Route>
            </Routes>
        </BrowserRouter>
    );
};


