import { Dialog } from "primereact/dialog"

const CalismaSonucDialog = ({calismaSonucDialogVisible, setCalismaSonucDialogVisible, data}:any) => {
    return (
        <Dialog style={{width: '60rem', height: "40vh"}} visible={calismaSonucDialogVisible} onHide={() => setCalismaSonucDialogVisible(false)}>
            <div className="flex flex-wrap gap-3">
                {data && data.data.map((item:any) => {
                    return (
                        <div className="flex flex-column gap-1">
                            <span>Kurum kodu: {item.kurumKodu}</span>
                            <span>Kurum Adi: {item.kurumAdi}</span>
                            <span>Kurum turu: {item.kurumTuru}</span>
                            <span>Kurum tipi: {item.kurumTipi}</span>
                        </div>
                    )
                })}
            </div>
        </Dialog>
    )
}

export default CalismaSonucDialog;