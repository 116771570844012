import { useEffect, useState } from "react";
import { useGetExercisesListQuery } from "../hooks/useGetDataQuery";
import { BlockUI } from "primereact/blockui";
import LibraryCarusel from "./libraryCarusel";
import { useOutletContext } from "react-router-dom";
import { Card } from "primereact/card";
import "./library.css";
import ExerciseVideoDialog from "./exerciseVideoDialog";
import LibraryTable from "./libraryTable";
import { Button } from "primereact/button";
import CreatePanelDialog from "./CreatePanelFunctionality/createPanelDialog";
import LibraryAccordion from "./libraryAccordion";

const Library = () => {
    const { t }: any = useOutletContext();
    const { myPanels }: any = useOutletContext();
    const { setMyPanel }: any = useOutletContext();
    const { systemPanels }: any = useOutletContext();

    const [blockUI, setBlockUI] = useState(true);


    const [lazyParamsForExercises, setLazyParamsForExercises] = useState<any>({
        first: 0,
        rows: 100,
    });

    const [exerciseProcedureList, setExerciseProcedureList] = useState<any>([]);

    const [caruselExercise, setCaruselExercise] = useState<any>([]);

    const [exerciseVideoDialogVisible, setExerciseVideoDialogVisible] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState<any>({});

    const [createPanelDialogVisible, setCreatePanelDialogVisible] = useState(false);


    const { data: exercisesData, isSuccess: isSuccessExerciseData, isError: isErrorExerciseData } =
        useGetExercisesListQuery(lazyParamsForExercises);

    useEffect(() => {
        const purifiedData = exercisesData?.data.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                image: item.imageUrl || "/exercise-image.svg",
                videoUrl: item.videoUrl,
                circleImage: "/exerciseCircleIcon.png"
            };
        });

        if (purifiedData) {

            setExerciseProcedureList(purifiedData);
            selectRandomExercise(purifiedData)
            setBlockUI(false)
        }
    }, [isSuccessExerciseData]);

    useEffect(() => {
        if (isErrorExerciseData) {
            setBlockUI(false)
        }
    }, [isErrorExerciseData]);


    const selectRandomExercise = (purifiedData: any) => {
        const validExercises = purifiedData.filter((exercise: any) => exercise.image !== "/exercise-image.svg");
        const shuffledExercises = validExercises.sort(() => Math.random() - 0.5);
        setCaruselExercise(shuffledExercises.slice(0, 10));
    }


    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >

            <div>
                <LibraryCarusel
                    caruselExercise={caruselExercise}
                    setExerciseVideoDialogVisible={setExerciseVideoDialogVisible}
                    setSelectedExercise={setSelectedExercise}
                    t={t} />

                <div className="grid gap-2">
                    <div className="col">
                        <Card style={{ borderRadius: "24px", position: "relative" }} >
                            <LibraryTable exerciseProcedureList={exerciseProcedureList}
                                setSelectedExercise={setSelectedExercise}
                                setExerciseVideoDialogVisible={setExerciseVideoDialogVisible}
                                t={t} />
                        </Card>
                    </div>

                    <div className="col ">
                        <Card className="h-full" style={{ borderRadius: "24px", position: "relative", overflow: "auto" }} >
                            <div className="flex flex-column gap-2 h-30rem">
                                <div className="flex justify-content-between align-items-center">
                                    <p className=" text-xl" style={{ color: "#727A99" }}>{t("myPanels")}</p>
                                    <Button
                                        label={t("createPanel")}
                                        icon={<img src="/addPatientIcon.svg" />}
                                        style={{
                                            backgroundColor: "#E3506B",
                                            color: "white",
                                            border: "#E3506B",
                                            height: "3rem",
                                            borderRadius: "10px",
                                            width: "11rem",
                                        }}
                                        onClick={() => setCreatePanelDialogVisible(true)}
                                    />
                                </div>
                                <div className="flex flex-column align-items-center justify-content-center">
                                    {myPanels.length === 0 &&
                                        <>
                                            <img src="/noPlanImage.png" style={{ width: "15rem", objectFit: "cover" }} className="mt-8" />
                                            <p className="font-bold text-lg mb-0" style={{ color: "#2D3967" }}>{t('noPanels')}</p>
                                            <p className="text-sm m-0" style={{ color: "#9EA4B9" }}>{t('shouldAddPanels')}</p>
                                        </>}
                                    {myPanels.length !== 0 &&
                                        <>
                                            <LibraryAccordion
                                                myPanels={myPanels}
                                                setMyPanel={setMyPanel}
                                                exerciseProcedureList={exerciseProcedureList}
                                                setExerciseVideoDialogVisible={setExerciseVideoDialogVisible}
                                                setSelectedExercise={setSelectedExercise} />
                                        </>}

                                </div>

                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            <CreatePanelDialog
                createPanelDialogVisible={createPanelDialogVisible}
                setCreatePanelDialogVisible={setCreatePanelDialogVisible}
                systemPanels={systemPanels}
                exerciseProcedureList={exerciseProcedureList}
                setMyPanel={setMyPanel}
                t={t}
            />
            <ExerciseVideoDialog
                exerciseVideoDialogVisible={exerciseVideoDialogVisible}
                setExerciseVideoDialogVisible={setExerciseVideoDialogVisible}
                selectedExercise={selectedExercise} />

        </BlockUI>
    )
}

export default Library