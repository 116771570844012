import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Slider } from "primereact/slider";
import React, { useEffect, useState } from "react";

const PatientHistory = ({ paitentHistoryDate, painDateOptions, setPainDateOptions, t }: any) => {
  const [notes, setNotes] = useState<any>()
  const [anemnez, setAnemnez] = useState<any>()

  useEffect(() => {
    if (painDateOptions) {
      setNotes(painDateOptions.find((date: any) => date.value === paitentHistoryDate).notes)
      setAnemnez(painDateOptions.find((date: any) => date.value === paitentHistoryDate).anemnez)
    }

  }, [paitentHistoryDate]);

  const saveAnemnez = () => {
    const index = painDateOptions.findIndex((date: any) => date.value === paitentHistoryDate)
    setPainDateOptions((prevDates: any) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = { ...updatedDates[index], anemnez: anemnez };
      return updatedDates;
    });
  }

  const saveNotes = () => {
    const index = painDateOptions.findIndex((date: any) => date.value === paitentHistoryDate)
    setPainDateOptions((prevDates: any) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = { ...updatedDates[index], notes: notes };
      return updatedDates;
    });
  }

  return (
    <div className="flex h-full flex-wrap justify-content-center align-items-start gap-4">
      <Card style={{
        borderRadius: "24px", position: "relative",
        backgroundImage: "url('/patternForPatientHistoryCards.png')",
        backgroundRepeat: "no-repeat"
      }}>
        <div className="flex justify-content-between align-items-start gap-4 px-4 pt-4">

          <div className="flex flex-column gap-6 pt-2">
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("painLocation")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{t("chest")}</p>
            </div>
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("diagnosisType")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{t("constant")}</p>
            </div>
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("duration")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>1 {t("week")}</p>
            </div>
          </div>

          <img src="/patientHistoryCardManImageFront.png" />
        </div>

        <div className="flex flex-column px-4 mb-2">
          <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("severity")}</p>
          <p className="m-0 text-lg font-bold" ><span style={{ color: "#E3506B" }}>7</span><span style={{ color: "#2D3967" }}>/10</span></p>
          <Slider className="w-full" value={7} max={10} min={0} />
        </div>

      </Card>
      <Card style={{
        borderRadius: "24px", position: "relative",
        backgroundImage: "url('/patternForPatientHistoryCards.png')",
        backgroundRepeat: "no-repeat"
      }}>
        <div className="flex justify-content-between align-items-start gap-4 px-4 pt-4">

          <div className="flex flex-column gap-6 pt-2">
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("painLocation")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{t("back")}</p>
            </div>
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("diagnosisType")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{t("constant")}</p>
            </div>
            <div>
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("duration")}</p>
              <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>1 {t("month")}</p>
            </div>
          </div>

          <img src="/patientHistoryCardManImageBack.png" />
        </div>

        <div className="flex flex-column px-4 mb-2">
          <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("severity")}</p>
          <p className="m-0 text-lg font-bold" ><span style={{ color: "#E3506B" }}>4</span><span style={{ color: "#2D3967" }}>/10</span></p>
          <Slider className="w-full" value={4} max={10} min={0} />
        </div>

      </Card>

      <div className="flex flex-column gap-4 patientHistory h-full">
        <div className="flex gap-4 h-full">
          <Card className="h-full w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4  px-4">
              <img src="/activityLevelIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('activityLevel')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('moderately')}</p>
              </div>

            </div>

          </Card>
          <Card className="h-full  w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4 px-4">
              <img src="/surgeryIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('prevSurgery')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('noSurgery')}</p>
              </div>

            </div>

          </Card>
          <Card className="h-full w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4  px-4">
              <img src="/stressLevelIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('stressLevel')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('high')}</p>
              </div>

            </div>

          </Card>
        </div>
        <div className="flex gap-4 h-full">
          <Card className="h-full w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4  px-4">
              <img src="/treatmentIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('prevTreatment')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('yes')}</p>
              </div>

            </div>

          </Card>
          <Card className="h-full w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4  px-4">
              <img src="/treatmentEffectIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('treatmentEffect')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('high')}</p>
              </div>

            </div>

          </Card>
          <Card className="h-full w-15rem" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-center align-items-center gap-4 py-4  px-4">
              <img src="/smokeIcon.svg" style={{ width: "3rem", height: "3rem" }} />

              <div className="flex flex-column justify-content-center align-items-start">
                <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t('smokeAlcohol')}</p>
                <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t('neither')}</p>
              </div>

            </div>

          </Card>
        </div>
        <div className="flex justify-content-between gap-4 h-full">
          <Card className="h-full " style={{ borderRadius: "24px", position: "relative" }}>
            <div className="px-4 pt-4 pb-4  flex flex-column gap-2">
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t('doctorNotes')}</p>
              <InputTextarea value={notes} onChange={(e) => setNotes(e.target.value)} rows={5} cols={35} />
              <Button
                className="w-full"
                onClick={saveNotes}
                style={{
                  backgroundColor: "#2D3967",

                  color: "white",
                  border: "#2D3967",
                  height: "2.5rem",
                  borderRadius: "10px",
                  fontWeight: "bold"
                }}
              >
                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                  <img src="/accept.svg" />
                  {t("save")}
                </span>
              </Button>
            </div>


          </Card>
          <Card className="h-full" style={{ borderRadius: "24px", position: "relative" }}>
            <div className="px-4 pt-4 pb-4 flex flex-column gap-2">
              <p className="m-0 text-md" style={{ color: "#727A99" }}>{t('anemnez')}</p>
              <InputTextarea value={anemnez} onChange={(e) => setAnemnez(e.target.value)} rows={5} cols={35} />
              <Button
                className="w-full"
                onClick={saveAnemnez}
                style={{
                  backgroundColor: "#E3506B",
                  color: "white",
                  border: "#E3506B",
                  height: "2.5rem",
                  borderRadius: "10px",
                  fontWeight: "bold"
                }}
              >
                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                  <img src="/accept.svg" />
                  {t("save")}
                </span>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PatientHistory;
