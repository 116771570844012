import Body from "./TimeLine/body";
import Days from "./TimeLine/days";

const TimeLineBody = ({t, filteredAppointments, days, times, selectedDay, setSelectedDay}: any) => {
    return (
        <>
            <div className="flex flex-column p-2">
                <Days t={t} days={days} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
                <Body filteredAppointments={filteredAppointments} times={times} />
            </div>
        </>
    )
}

export default TimeLineBody;