import { AppointmentStatus } from "./appointmentStatus";

export const PrepareAppointmentColors = (appointment:any) => {
    const backgroundColor = appointment
      ? appointment.status === AppointmentStatus.Completed.value
        ? AppointmentStatus.Completed.backgroundColor
        : appointment.status === AppointmentStatus.Cancelled.value
        ? AppointmentStatus.Cancelled.backgroundColor
        : appointment.status === AppointmentStatus.Pending.value
        ? AppointmentStatus.Pending.backgroundColor
        : appointment.status === AppointmentStatus.Approved.value
        ? AppointmentStatus.Approved.backgroundColor
        : appointment.status === AppointmentStatus.Expire.value
        ? AppointmentStatus.Expire.backgroundColor
        : "white"
      : "white";

    const situationColor = appointment
      ? appointment.status === AppointmentStatus.Completed.value
        ? AppointmentStatus.Completed.textColor
        : appointment.status === AppointmentStatus.Cancelled.value
        ? AppointmentStatus.Cancelled.textColor
        : appointment.status === AppointmentStatus.Pending.value
        ? AppointmentStatus.Pending.textColor
        : appointment.status === AppointmentStatus.Approved.value
        ? AppointmentStatus.Approved.textColor
        : appointment.status === AppointmentStatus.Expire.value
        ? AppointmentStatus.Expire.textColor
        : "inherit"
      : "inherit";

    return { backgroundColor, situationColor };
}