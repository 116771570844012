import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Filler, CategoryScale } from 'chart.js';
import { useRef } from 'react';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Filler, CategoryScale);


const AdherenceChart = () => {
    const chartRef = useRef(null);

    const data = {
      labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      datasets: [
        {
          label: "Adherence",
          data: [1, 3, 2, 6, 5, 3, 4],
          fill: true,
          backgroundColor: (context:any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
  
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            gradient.addColorStop(0, "rgba(100, 173, 240, 0.6)"); 
            gradient.addColorStop(1, "rgba(100, 173, 240, 0)");   
            return gradient;
          },
          borderColor: "#64ADF0",
          borderWidth:2, 
          tension: 0.5,
          pointRadius: 0, 
        },
      ],
    };
  
    const options:any = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: "#9E9E9E", // Gray color for labels
            font: {
              family: "Arial",
              weight: "600",
            },
          },
        },
        y: {
          grid: {
            color: "rgba(0, 0, 0, 0.05)", // Light grid color
          },
          ticks: {
            display: false, // Hide y-axis labels
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
    };
  
    return (
      <div style={{ width: "100%", height: "10rem" }}>
        <Line ref={chartRef} data={data} options={options} />
      </div>
    );
};

export default AdherenceChart;
