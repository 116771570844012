const Days = ({t, days, selectedDay, setSelectedDay} :any) => {
    const handleDayClick = (id: any) => {
        setSelectedDay(days.findIndex((d:any) => d.id === id));
    }

    return (
        <>
            <div id="timeline-days-wrapper" className="flex justify-content-between align-items-center overflow-x-auto gap-6 p-2">
                {days && days.map((day : any) => (
                    <div key={day.id} className={`flex justify-content-center align-items-center flex-column gap-2 ${day.isNextMonth ? "opacity-50" : "cursor-pointer"}`} onClick={() => !day.isNextMonth && handleDayClick(day.id)}>
                        <div className={`flex justify-content-center align-items-center font-bold text-xl w-3rem h-3rem border-circle ${days[selectedDay].id === day.id ? "text-white" : "surface-100 text-blue-900"}`} style={{ backgroundColor: `${days[selectedDay].id === day.id && "#2D3967"}` }}>{day.date.getDate()}</div>
                        <div className="text-500 text-sm font-light">{t(day.date.toLocaleDateString("en-EN", { weekday: 'short' }))}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Days;