import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const PatientStatisticsChart = ({ t }: any) => {
    const data = {
        labels: [t("Mon"), t("Tue"), t("Wen"), t("Thu"), t("Fri"), t("Sat"), t("Sun"), t("Mon"), t("Tue"), t("Wen"), t("Thu"), t("Fri")],
        datasets: [
            {
                label: 'Past Patients',
                data: [300, 400, 350, 200, 180, 250, 280, 300, 420, 380, 190, 210],
                backgroundColor: '#2D3967',
                borderRadius: 50,
                barPercentage: 0.4,
                stack: 'Stack 0',
            },
            {
                label: 'New Patients',
                data: [120, 160, 140, 100, 90, 130, 150, 180, 140, 130, 110, 100],
                backgroundColor: '#E3506B',
                borderRadius: 50,
                barPercentage: 0.4,
                stack: 'Stack 0',
            },
            {
                label: 'Ongoing Patients',
                data: [200, 240, 300, 250, 220, 300, 400, 280, 420, 450, 350, 280],
                backgroundColor: '#64ADF0',
                borderRadius: 50,
                barPercentage: 0.4,
                stack: 'Stack 0',
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: null,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className='flex justify-content-between align-items-center p-4'>
            <div className='flex flex-column justify-content-center align-items-start mb-4 gap-5'>

                <p className=' text-xl mb-0 mt-1' style={{ color: "#727A99", whiteSpace: "nowrap" }}>{t("patientStatistics")}</p>
                <div className='flex justify-content-center align-items-start gap-2 mt-2'>
                    <span style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        backgroundColor: "#64ADF0",
                        borderRadius: '6px',
                    }}></span>
                    <div className='flex flex-column'>
                        <p className='m-0 font-bold text-3xl' style={{ color: "#2D3967", lineHeight: '1.2rem' }}>1450</p>
                        <p className='m-0 text-xs' style={{ color: "#727A99", whiteSpace: 'nowrap' }}>{t('ongoingPatients')}</p>
                    </div>
                </div>
                <div className='flex justify-content-center align-items-start gap-2'>
                    <span style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        backgroundColor: "#E3506B",
                        borderRadius: '6px',
                    }}></span>
                    <div className='flex flex-column'>
                        <p className='m-0 font-bold text-3xl' style={{ color: "#2D3967", lineHeight: '1.2rem' }}>945</p>
                        <p className='m-0 text-xs' style={{ color: "#727A99", whiteSpace: 'nowrap' }}>{t('newPatients')}</p>
                    </div>
                </div>
                <div className='flex justify-content-center align-items-start gap-2'>
                    <span style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        backgroundColor: "#2D3967",
                        borderRadius: '6px',
                    }}></span>
                    <div className='flex flex-column'>
                        <p className='m-0 font-bold text-3xl' style={{ color: "#2D3967", lineHeight: '1.2rem' }}>1682</p>
                        <p className='m-0 text-xs' style={{ color: "#727A99", whiteSpace: 'nowrap' }}>{t('pastPatients')}</p>
                    </div>
                </div>


            </div>
            <div style={{ height: '15rem', width: '75%' }}>
                <Bar data={data} options={options} />
            </div>
        </div>

    );
};

export default PatientStatisticsChart;
