import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PatientTable from '../Utils/Tables/patientTable';
import { Card } from 'primereact/card';
import PatientCarusel from './patientCarusel';
import "./patients.css";
import AddPatientDialog from '../Utils/AddPatientDialog/addPatientDialog';


const Patients = () => {
    const { t }: any = useOutletContext();
    const { patientList }: any = useOutletContext();
    const { setPatientList }: any = useOutletContext();
    const { lazyParamsForPatients }: any = useOutletContext();

    const [addPatientDialogVisible, setAddPatientDialogVisible] = useState(false);
    const [caruselPatients, setCaruselPatients] = useState<any>([]);

    useEffect(() => {
        if (patientList) {
            const addPatient = {
                add: true
            };

            const emptyPatient = {
                empty: true
            }

            if (patientList.length >= 4) {
                setCaruselPatients([addPatient, ...(patientList?.slice(0, 10) || [])]);
            }
            else {
                const tempPatientList: any = [addPatient]
                for (let i = 0; i < 4; i++) {
                    if (patientList[i]) {
                        tempPatientList.push(patientList[i])
                    }
                    else {
                        tempPatientList.push(emptyPatient)
                    }
                }
                setCaruselPatients(tempPatientList)
            }
        }
    }, [patientList]);

    return (
        <div>
            <PatientCarusel caruselPatients={caruselPatients}
                setAddPatientDialogVisible={setAddPatientDialogVisible} t={t} />

            <Card style={{ borderRadius: "24px", position: "relative" }} className='mt-3'>
                <PatientTable patientList={patientList}
                    lazyParamsForPatients={lazyParamsForPatients} t={t} />
            </Card>

            <AddPatientDialog
                t={t}
                patientList={patientList}
                setPatientList={setPatientList}
                addPatientDialogVisible={addPatientDialogVisible}
                setAddPatientDialogVisible={setAddPatientDialogVisible}
            />
        </div>
    );

}

export default Patients