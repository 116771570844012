import { Avatar } from "primereact/avatar";
import { PrepareAppointmentColors } from "../../../Utils/AppointmentStatusUtilities/prepareAppointmentColor";
import { Image } from "primereact/image";

const Body = ({ filteredAppointments, times }: any) => {
    const getStartTimeFromAppointment = (dateAppointmentStart: any) => {
        const date = new Date(dateAppointmentStart);
        const hour = date.getHours();
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedTime = `${formattedHour}:00`;
        return formattedTime;
    };

    const getStartEndHour = ({ dateAppointmentStart, dateAppointmentEnd }: any) => {
        const formatTime = (date: Date) => {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const formattedHour = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            return `${formattedHour}:${formattedMinutes}`;
        };

        const formatTimeWithPeriod = (date: Date) => {
            const hours = date.getHours();
            const period = hours >= 12 ? 'P.M.' : 'A.M.';
            return `${formatTime(date)} ${period}`;
        };

        const formattedStartTime = formatTime(dateAppointmentStart);
        const formattedEndTime = formatTimeWithPeriod(dateAppointmentEnd);

        return { formattedStartTime, formattedEndTime };
    };

    return (
        <>
            <div className="flex overflow-y-auto overflow-x-auto h-25rem">
                <div className="flex flex-column w-full gap-4 relative">
                    {times && times.map((time: any, index:number) => (
                        <div key={time.id} className={`flex justify-content-between align-items-center pr-2 ${(index === 0) && "pt-3"} ${(index === times.length - 1) && "pb-3"}`}>
                            <div className="text-400 p-3">
                                {time.time}
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    borderTop: `1px ${time.isNow ? "solid" : "dashed"} ${time.isNow ? "#E3506B" : "var(--surface-300)"}`,
                                    width: "100%",
                                    margin: "8px 0",
                                    minWidth:"200rem"
                                }}
                            >
                                {time.isNow && <span
                                    style={{
                                        position: "absolute",
                                        top: "-0.23rem",
                                        width: "0.4rem",
                                        height: "0.4rem",
                                        backgroundColor: time.isNow ? "#E3506B" : "var(--surface-300)",
                                        borderRadius: "50%",
                                    }}
                                />}
                            </div>
                            <div className="flex gap-3 w-full h-4rem pr-4 absolute pl-8">
                                {filteredAppointments && filteredAppointments.map((appointment: any) => {
                                    const formattedTime = getStartTimeFromAppointment(appointment.dateAppointmentStart);
                                    if (formattedTime === time.time) {
                                        const { formattedStartTime, formattedEndTime } = getStartEndHour(appointment);
                                        const { backgroundColor, situationColor } = PrepareAppointmentColors(appointment);
                                        return (
                                            <div key={appointment.id} className="flex justify-content-between align-items-center w-full p-2 border-round-xl gap-2" style={{ backgroundColor: backgroundColor }}>
                                                <div className="flex gap-3 flex-grow-1" style={{ minWidth: 0 }}>
                                                    <div className="flex justify-content-center align-items-center flex-shrink-0">
                                                        <Image
                                                            src={`/${appointment.status === "Completed" ? "Check" : appointment.status === "Pending" ? "Loading" : "Check"}.svg`}
                                                            width="25"
                                                            height="25"
                                                            className="flex justify-content-center align-items-center p-2"
                                                            style={{ backgroundColor: situationColor, borderRadius: "10px" }}
                                                        />
                                                    </div>
                                                    <div className="flex flex-column overflow-hidden" style={{ minWidth: 0 }}>
                                                        <div className="text-lg font-md  text-truncate" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:"#2D3967" }}>
                                                            {appointment.firstName + " " + appointment.lastName} 
                                                        </div>
                                                        <div className="text-md font-light text-truncate" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:"#727A99" }}>
                                                            {formattedStartTime + " - " + formattedEndTime}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex gap-3 justify-content-center align-items-center flex-shrink-1" style={{ minWidth: "6rem", maxWidth: "12rem", flexGrow: 1 }}>
                                                    <div className="flex justify-content-center align-items-center w-full h-2rem p-2 border-round-xl bg-white overflow-hidden">
                                                        <span style={{ color: situationColor, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexGrow: 1, textAlign: "center" }}>
                                                            {appointment.status}
                                                        </span>
                                                    </div>
                                                    <Avatar
                                                        image="/man.png"
                                                        className="w-3rem h-3rem border-radius-xl"
                                                        shape="circle"
                                                        style={{ flexShrink: 0 }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Body;