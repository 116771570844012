import { Toast } from "primereact/toast";
import { useContext, useRef } from "react";
import { createContext } from "react";

interface ToastContextType {
    showToast: (options: any) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastContextProvider = ({ children }: any) => {
    const toastRef = useRef<any>(null);

    const showToast = (options: any) => {
        if(!toastRef.current) return;
        toastRef.current.show(options);
    }

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Toast ref={toastRef} />
            {children}
        </ToastContext.Provider>
    )
}

export const useToastContext = () => {
    const context = useContext(ToastContext);
    if(!context) {
        throw new Error('useToastContext must be used within a ToastContextProvider');
    }
    return context;
}