import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Card } from "primereact/card";

ChartJS.register(ArcElement, Tooltip, Legend);

const ExerciseDetails = ({ t, selectedExercise } : any) => {
    const [data, setData] = useState<any> ({
        1: [
            {
                repeat: "1",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "2",
                success: "90"
            },
            {
                repeat: "2",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "0",
                success: "70"
            },
            {
                repeat: "3",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "1",
                success: "80"
            },
        ],
        2: [
            {
                repeat: "1",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "2",
                success: "90"
            },
            {
                repeat: "2",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "0",
                success: "70"
            },
            {
                repeat: "3",
                startTime: "13:52:06",
                duration: "165",
                angle: "90",
                fault: "1",
                success: "80"
            },
        ],
    })

    const inlineAccordionDatatableColumns = [
        {
            field: "repeat",
        },
        {
            field: "startTime"
        },
        {
            field: "duration"
        },
        {
            field: "angle"
        },
        {
            field: "fault"
        },
        {
            field: "success"
        }
    ]

    const durationBodyTemplate = (rowData : any) => <span className="text-base">{rowData.duration} {t("secs")}</span>

    const repeatBodyTemplate = (rowData : any) => <span className="text-base">{rowData.repeat + " x " + 15 + " " + t("repeat")}</span>

    const referanceAngleBodyTemplate = (rowData : any) => <span className="text-base">{rowData.angle} {t("degree")}</span>

    const inlineTableSuccessBodyTemplate = (rowData : any) => <span className="text-base">{rowData.success}%</span>

    const inlineAccordionDatatableDynamicColumns = (key:any) => {
        return inlineAccordionDatatableColumns.map((col:any) => {
            if(col.field === "repeat") {
                return <Column key={col.field} field={col.field} header={`${t("set")} ${key}`} body={repeatBodyTemplate} />
            } else if (col.field === "startTime") {
                return <Column key={col.field} field={col.field} header={t("startTime")} />
            } else if (col.field === "duration") {
                return <Column key={col.field} field={col.field} header={t("duration")} body={durationBodyTemplate} />
            } else if (col.field === "angle") {
                return <Column key={col.field} field={col.field} header={t("angle")} body={referanceAngleBodyTemplate} />
            } else if (col.field === "fault") {
                return <Column key={col.field} field={col.field} header={t("fault")} />
            } else if (col.field === "success") {
                return <Column key={col.field} field={col.field} header={t("success")} body={inlineTableSuccessBodyTemplate} />
            }
        })
    }

    const header = (day : string) => {
        return (
            <div className="flex justify-content-between align-items-center h-4rem">
                <div className="flex align-items-center justify-content-center w-7rem h-full" style={{backgroundColor: "#E3506B"}}>
                    <span className="text-md text-white">{day}</span>
                </div>
                <div className="flex align-items-center justify-content-between gap-3 text-black-alpha-90 font-light w-full h-full p-5" style={{backgroundColor: "#2D3967"}}>
                    <div className="">
                        <span className="text-base text-white">{t("Monday")}</span>{" "}
                        <span className="text-base" style={{color: "#BEC2D0"}}>( 3 x 15 {t("repeat")} )</span>
                    </div>
                    <div className="">
                        <span className="text-base" style={{color: "#BEC2D0"}}>{t("completed")}</span>{" "}
                        <span className="text-base text-white">7/15</span>
                    </div>
                    <div className="">
                        <span className="text-base" style={{color: "#BEC2D0"}}>{t("success")}</span>{" "}
                        <span className="text-base text-white">96 %</span>
                    </div>
                    <span className="text-base font-bold" style={{color: "#BEC2D0"}}>30.12.2024</span>
                </div>
            </div>
        )
    }

    const chartRef: any = useRef(null);

    const getGradient = (ctx: any) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "#2D3967"); // Red
        gradient.addColorStop(0.3, "#E3506B"); // Blue
        return gradient;
    };

    const value = 90;

    const dataDoughnot = {
        datasets: [
            {
                data: [value, 100 - value],
                backgroundColor: chartRef.current
                    ? [getGradient(chartRef.current.ctx), "#EBEDF2"]
                    : ["#2D3967", "#EBEDF2"],
                borderWidth: 3,
                borderRadius: 50,
                cutout: "70%",
            },
        ],
    };

    const options: any = {
        rotation: 180,
        circumference: 360,
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: null,
        },
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };


    return (
        <Card style={{borderRadius: "24px"}}>
            <div className="flex flex-column gap-3">
                <div className="flex justify-content-between align-items-center">
                    <span className="text-2xl" style={{color: "#727A99"}}>{selectedExercise.name}</span>
                    <div className="flex gap-3 align-items-center">
                        <span className="text-2xl" style={{color: "#2D3967"}}>{t("averageSuccess")}</span>
                        <div style={{ position: "relative", width: "3.5rem", height: "3.5rem" }}>
                            <Doughnut ref={chartRef} data={dataDoughnot} options={options} />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    textAlign: "center",
                                }}
                            >
                                <span style={{ fontSize: "13px", fontWeight: "bold", color: "#2D3967" }}>{value} <span style={{ color: "#9EA4B9", fontSize: "10px" }}>%</span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <Accordion multiple className="exercise-details-accordion">
                    <AccordionTab header={() => header(`${t("day")} 1`)}>
                        {data && Object.keys(data).map((key:any) => {
                            return (
                                <DataTable className="accordion-inline-datatable" value={data[key]}>
                                    {inlineAccordionDatatableDynamicColumns(key)}
                                </DataTable>
                            )
                        })}
                    </AccordionTab>
                    <AccordionTab header={() => header(`${t("day")} 2`)}>
                        {data && Object.keys(data).map((key:any) => {
                            return (
                                <DataTable className="accordion-inline-datatable" value={data[key]}>
                                    {inlineAccordionDatatableDynamicColumns(key)}
                                </DataTable>
                            )
                        })}
                    </AccordionTab>
                </Accordion>
            </div>
        </Card>
    )
}

export default ExerciseDetails;