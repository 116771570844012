import { Card } from "primereact/card";
import { Carousel } from "primereact/carousel"
import { useEffect, useState } from "react"
import { useAddPaln } from "../../hooks/useGetDataQuery";
import { useToastContext } from "../../Lib/Contexts/toastContext";
import SuccessChart from "./successChart";
import CompleteChart from "./completeChart";
import { Slider } from "primereact/slider";
import AdherenceChart from "./adherenceChart";
import { Button } from "primereact/button";

const PlansCard = ({ plan, setPlan, planList, setPlanList, t, handleDetails }: any) => {
    const { showToast }: any = useToastContext();
    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";
    const [cardPlanList, setCardPlanList] = useState<any>([])

    const responsiveOptions = [
        {
            breakpoint: '1440px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const {
        mutate: addPlanMutation,
        isSuccess: isAddPlanSuccess,
        isError: isAddPlanError
    } = useAddPaln();


    useEffect(() => {
        if (planList) {
            const emptyPLan = {
                empty: true
            }

            if (planList.length < 4) {
                const tempPlanList: any = []
                for (let i = 0; i < 4; i++) {
                    if (planList[i]) {
                        tempPlanList.push(planList[i])
                    }
                    else {
                        tempPlanList.push(emptyPLan)
                    }
                }
                setCardPlanList(tempPlanList)
            }
            else {
                setCardPlanList(planList)
            }
        }
    }, [planList]);

    useEffect(() => {
        if (plan) {
            addPlanMutation(plan);
        }
    }, [plan]);

    useEffect(() => {
        if (isAddPlanSuccess) {
            showToast({
                severity: "success",
                summary: "Success",
                detail: "Exercises saved successfully!",
            });
            setPlanList((prevPlans: any) => prevPlans ? [...prevPlans, plan] : [plan])
            setPlan(undefined);
        }
    }, [isAddPlanSuccess]);

    useEffect(() => {
        if (isAddPlanError) {
            showToast({
                severity: "error",
                summary: "Error",
                detail: "Exercises could not be saved",
            });
            setPlan(undefined);
        }
    }, [isAddPlanError]);

    const dateBodyTemplate = (rowData: any, key: any) => {
        if (rowData[key]) {
            const formattedDate = new Date(rowData[key]).toLocaleDateString(lang, {
                month: "short",
                day: "numeric",
                year: "numeric",
            });
            return formattedDate
        };
    }

    const planTemplate = (plan: any) => {
        if (!plan.empty) {
            return (
                <Card className='mx-2' style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-start gap-6 p-2" style={{ whiteSpace: "nowrap" }}>
                        <div className="flex justify-content-between align-items-center">
                            <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{plan.name}</p>
                            <img onClick={handleDetails} src="/presentation.svg" alt="Presentation Icon" className="cursor-pointer" />
                        </div>

                        <div className="flex justify-content-center gap-6 ">
                            <CompleteChart t={t} empty={plan.empty} />
                            <SuccessChart t={t} empty={plan.empty} />
                        </div>

                        <div className="flex flex-column gap-2">
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dateOfStart")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>{dateBodyTemplate(plan, "planDateStart")}</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dateOfEnd")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>{dateBodyTemplate(plan, "planDateEnd")}</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dailyTime")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}> {plan.dailyTime ? plan.dailyTime : "--"} min</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("totalExercise")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>{plan.totalExerciseCount ? plan.totalExerciseCount : plan.exerciseList?.length} {t("exercises")}</p>
                            </div>
                        </div>
                    </div>
                </Card>
            )
        } else {
            return (
                <Card className='m-2' style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-start gap-6 p-2" style={{ whiteSpace: "nowrap" }}>
                        <div className="flex justify-content-between">
                            <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{plan.name}</p>
                            <img src="/presentation.svg" alt="Exercise Icon" />
                        </div>

                        <div className="flex justify-content-center gap-6 ">
                            <CompleteChart t={t} empty={plan.empty} />
                            <SuccessChart t={t} empty={plan.empty} />
                        </div>

                        <div className="flex flex-column gap-2">
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dateOfStart")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>-</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dateOfEnd")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>-</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("dailyTime")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}> -</p>
                            </div>
                            <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-between">
                                <p className="m-0 text-sm " style={{ color: "#727A99" }}>{t("totalExercise")}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>-</p>
                            </div>
                        </div>
                    </div>
                </Card>
            )
        }

    }


    return (
        <div className="grid">
            <div className="col-12">
                <Carousel value={cardPlanList} numVisible={4} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={planTemplate} />
            </div>
            <div className="col">
                <Card className="h-full" style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-start gap-4 p-2" style={{ whiteSpace: "nowrap" }}>
                        <div className="flex justify-content-between">
                            <p className="m-0 text-lg " style={{ color: "#727A99" }}>{t('monthlyExerciseAnalytics')}</p>
                            <img src="/exerciseIcon.svg" alt="Exercise Icon" />
                        </div>

                        <div className="flex flex-column gap-2">
                            <div className="flex justify-content-between">
                                <p className="m-0 text-md " style={{ color: "#2D3967" }}>{t('Performed')}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>20%</p>
                            </div>
                            <Slider className="w-full" value={2} max={10} min={0} />
                        </div>

                        <div className="flex flex-column gap-2">
                            <div className="flex justify-content-between">
                                <p className="m-0 text-md " style={{ color: "#2D3967" }}>{t('Upcoming')}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>65%</p>
                            </div>
                            <Slider className="w-full" value={6.5} max={10} min={0} />
                        </div>

                        <div className="flex flex-column gap-2">
                            <div className="flex justify-content-between">
                                <p className="m-0 text-md " style={{ color: "#2D3967" }}>{t('Performed')}</p>
                                <p className="m-0 text-md font-bold " style={{ color: "#2D3967" }}>15%</p>
                            </div>
                            <Slider className="w-full" value={1.5} max={10} min={0} />
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col">
                <Card className="h-full" style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-start gap-4 p-2" style={{ whiteSpace: "nowrap" }}>
                        <div className="flex justify-content-between align-items-center">
                            <p className="m-0 text-lg " style={{ color: "#727A99" }}>{t('adherence')}</p>
                            <Button
                                style={{
                                    width: "10rem",
                                    height: "2.5rem",
                                    borderRadius: "10px",
                                    border: "1.5px solid #2D3967",
                                    color: "#2D3967",
                                    backgroundColor: "white"
                                }}  >
                                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                    <img src="/adherenceIcon.svg" />
                                    <p>{t("October").slice(0, 3)}, 2024</p>
                                </span>
                            </Button>

                        </div>

                        <AdherenceChart />
                    </div>

                </Card>
            </div>

        </div>


    )
}

export default PlansCard