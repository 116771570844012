import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PlansCard from './PlansCardCarusel/plansCard';
import { useGetPlanListByPatientQuery } from '../hooks/useGetDataQuery';
import { BlockUI } from 'primereact/blockui';
import "./prescription.css"
import ExerciseDetailsView from './ExerciseDetail/exerciseDetailsView';
import AddExercisesDialog from '../Utils/ExerciseDialog/addExercisesDialog';
import PatientDetailCard from './patientDetailCard';
import PatientHistory from './patientHistory';
import AppointmentTable from '../Utils/Tables/appointmentTable';
import { Card } from 'primereact/card';


const Prescription = () => {
    const { t }: any = useOutletContext();
    const { appointmentListWithAllData }: any = useOutletContext();
    const { lazyParamsForAppointments }: any = useOutletContext();


    const [exerciseDialogVisible, setExerciseDialogVisible] = useState<any>();

    const [selectedPatient, setSelectedPatient] = useState<any>(JSON.parse(localStorage.getItem("patient") || '{}'));

    const { data, isSuccess, isError } = useGetPlanListByPatientQuery(selectedPatient?.id);

    const [seeAllPlans, setSeeAllPlans] = useState<boolean>(true);

    const [planList, setPlanList] = useState<any>([])
    const [plan, setPlan] = useState<any>()
    const [blockUI, setBlockUI] = useState(true);

    const [tabIndex, setTabIndex] = useState<any>(0)


    const [painDateOptions, setPainDateOptions] = useState<any>(
        [
            {
                name: "22/7/2023",
                value: new Date(2023, 7, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/8/2023",
                value: new Date(2023, 8, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/9/2023",
                value: new Date(2023, 9, 22),
                anemnez: "",
                notes: ""
            }

        ]
    )

    const [paitentHistoryDate, setPaitentHistoryDate] = useState<any>(painDateOptions[0].value)

    const [allUserAppointments, setAllUserAppointments] = useState<any>(0)

    const handleDetails = () => {
        setSeeAllPlans(false);
    }


    useEffect(() => {
        if (appointmentListWithAllData) {
            setAllUserAppointments(appointmentListWithAllData.filter((appointment: any) => appointment.patientId === selectedPatient.id))
        }
    }, [appointmentListWithAllData]);


    useEffect(() => {
        if (isSuccess) {
            setPlanList(data?.data)
            setBlockUI(false);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isError) setBlockUI(false);
    }, [isError]);

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            {selectedPatient ? (
                <div className="flex flex-column justify-content-center gap-4">
                    <PatientDetailCard
                        t={t}
                        selectedPatient={selectedPatient}
                        planList={planList}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        setExerciseDialogVisbible={setExerciseDialogVisible}
                        paitentHistoryDate={paitentHistoryDate}
                        setPaitentHistoryDate={setPaitentHistoryDate}
                        painDateOptions={painDateOptions} />

                    {tabIndex === 0 &&
                        <>
                            {seeAllPlans ? (
                                <PlansCard t={t} plan={plan} setPlan={setPlan} planList={planList} setPlanList={setPlanList} handleDetails={handleDetails} />
                            ) : (
                                <ExerciseDetailsView t={t} setSeeAllPlans={setSeeAllPlans} />
                            )}
                        </>}

                    {tabIndex === 1 &&
                        <>
                            <PatientHistory
                                paitentHistoryDate={paitentHistoryDate}
                                painDateOptions={painDateOptions}
                                setPainDateOptions={setPainDateOptions}
                                t={t} />
                        </>
                    }

                    {tabIndex === 2 &&
                        <>
                            <Card style={{ borderRadius: "24px", position: "relative" }}>
                                <AppointmentTable
                                    upcomingAppointments={allUserAppointments}
                                    lazyParamsForAppointments={lazyParamsForAppointments}
                                    t={t}
                                />
                            </Card>
                        </>}
                    <AddExercisesDialog t={t} 
                    planList={planList} 
                    selectedPatient={selectedPatient} 
                    exerciseDialogVisible={exerciseDialogVisible} 
                    setExerciseDialogVisible={setExerciseDialogVisible} 
                    setPlan={setPlan} 
                    setSelectedPatient={setSelectedPatient}/>
                </div>
            ) : (
                <>
                </>
            )}
        </BlockUI>
    )
}

export default Prescription