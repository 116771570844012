import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { useToastContext } from "../../Lib/Contexts/toastContext";
import { useUpdateAppointmentStatus } from "../../hooks/useGetDataQuery";

const CancelPatientAppointmentDialog = ({ cancelAppointmentDialog, setCancelAppointmentDialog,
    appointmentIdToCancel, setAppointmentList, setAppointmentListWithAllData, setUpcomingAppointments, t }: any) => {
    const [cancelAppointmentReason, setCancelAppointmentReason] = useState<any>();
    const [reasonForCancelText, setReasonForCancelText] = useState<any>()

    const { showToast }: any = useToastContext();

    const {
        mutate: updateAppointmentMutation,
        isSuccess: isSuccessUpdateAppointmentMutation,
        isError: isErrorUpdateAppointmentMutation
    } = useUpdateAppointmentStatus();

    useEffect(() => {
        if (isSuccessUpdateAppointmentMutation) {
            showToast({
                severity: "success",
                summary: "success",
                detail: "Appointment canceled successfully",
            });
        }
    }, [isSuccessUpdateAppointmentMutation]);

    useEffect(() => {
        if (isErrorUpdateAppointmentMutation) {
            showToast({
                severity: "error",
                summary: "error",
                detail: "Error",
            });
        }
    }, [isErrorUpdateAppointmentMutation]);


    const cancelOptions = [
        { name: t("patientAbsence"), key: 'absense' },
        { name: t("notAvailable"), key: 'availablity' },
        { name: t("anotherReason"), key: 'another' },
    ];

    const onHideCancelAppointmentDialog = () => {
        setCancelAppointmentReason(null)
        setReasonForCancelText("")
        setCancelAppointmentDialog(false)
    }

    const cancelAppointment = () => {
        setAppointmentList((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== appointmentIdToCancel));
        setAppointmentListWithAllData((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== appointmentIdToCancel));
        setUpcomingAppointments((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== appointmentIdToCancel));

        onHideCancelAppointmentDialog()

        updateAppointmentMutation({
            id: appointmentIdToCancel,
            status: "ConsultantCancelled",
            cancelReason: reasonForCancelText ? reasonForCancelText : cancelAppointmentReason.name,
            dateCancelled: new Date()
        })
    }


    const footerContent = (
        <div className="flex justify-content-end gap-2">
            <Button
                style={{
                    backgroundColor: "white",
                    width: "10rem",
                    height: "3rem",
                    borderRadius: "10px",
                    border: "1.5px solid #2D3967",
                    color: "#2D3967",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem"
                }}
                onClick={onHideCancelAppointmentDialog}
            >
                <img src="/cancel.svg" />
                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("cancel")}</p>
            </Button>
            <Button
                style={{
                    backgroundColor: "#E3506B",
                    width: "10rem",
                    height: "3rem",
                    borderRadius: "10px",
                    color: "white",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem"
                }}
                onClick={cancelAppointment}
            >
                <img src="/accept.svg" />
                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("yes")}</p>
            </Button>
        </div>
    );

    const headerContent = (
        <p className="m-0 text-md" style={{ color: "#727A99" }}>{t('cancelAppointment')}</p>
    );



    return (
        <Dialog footer={footerContent}
            header={headerContent}
            visible={cancelAppointmentDialog}
            style={{ width: '30vw', borderRadius: '24px', overflow: "hidden" }} onHide={onHideCancelAppointmentDialog}>

            <div className="card flex justify-content-start mt-4">
                <div className="flex flex-column gap-3">
                    {cancelOptions.map((options) => {
                        return (
                            <div key={options.key} className="flex align-items-center">
                                <RadioButton inputId={options.key} name="category" value={options} onChange={(e) => {
                                    if (e.value.key !== 'another') {
                                        setReasonForCancelText("");
                                    }
                                    setCancelAppointmentReason(e.value)
                                }}
                                    checked={cancelAppointmentReason?.key === options.key} />
                                <label style={{ color: '#2D3967' }} htmlFor={options.key} className="ml-2">{options.name}</label>
                            </div>
                        );
                    })}
                    <InputTextarea className="w-full" autoResize value={reasonForCancelText} onChange={(e) => setReasonForCancelText(e.target.value)} rows={3} cols={100}
                        disabled={cancelAppointmentReason?.key !== 'another'} />

                    <p className="text-md font-bold mt-0" style={{ color: '#2D3967' }}>{t("cancelConfirmation")}</p>
                </div>
            </div>
        </Dialog>
    )

}

export default CancelPatientAppointmentDialog