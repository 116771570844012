import { Dialog } from "primereact/dialog"
import "./patientCodeDialog.css"
import { Button } from "primereact/button";
import { InputOtp } from 'primereact/inputotp';
import { useEffect, useState } from "react";
import { useGetAccessCode, useSubmitAccessCode } from "../../hooks/useGetDataQuery";
import { BlockUI } from "primereact/blockui";
import { useToastContext } from "../../Lib/Contexts/toastContext";

const PatientCodeDialog = ({ codeDialogVisible, selecetedPatient, setSelectedPatient, t, carePlanName, selectedExercises, onCodeDialogHide }: any) => {

    const {
        mutate: getAccessCodeMutation,
        data: accessCodeData,
        isSuccess: isSuccessGetAccessCode,
    } = useGetAccessCode();

    const {
        mutate: submitAccessCodeMutation,
        isSuccess: isSuccessSubmitAccessCode,
        isError: isErrorSubmitAccessCode
    } = useSubmitAccessCode();


    const { showToast } = useToastContext();
    const [blockUI, setBlockUI] = useState(false);

    const [giftCardIsPresent, setGiftCardIsPresent] = useState(true);
    const [intro, setIntro] = useState(true);
    const [accessCode, setAccessCode] = useState<any>();
    const [accessCodeIsNotGiven, setAccessCodeIsNotGiven] = useState(false);


    useEffect(() => {
        if (isSuccessGetAccessCode) {
            setSelectedPatient((prevState: any) => ({
                ...prevState,
                code: accessCodeData.data.code
            }));
            setBlockUI(false)
        }
    }, [isSuccessGetAccessCode]);

    useEffect(() => {
        if (isSuccessSubmitAccessCode) {
            setSelectedPatient((prevState: any) => ({
                ...prevState,
                code: accessCode
            }));
            setBlockUI(false)
            onCodeDialogHide()
        }
    }, [isSuccessSubmitAccessCode]);

    useEffect(() => {
        if (isErrorSubmitAccessCode) {
            showToast({ severity: 'error', summary: 'Error', detail: t("codeError") });
            setBlockUI(false)
        }
    }, [isErrorSubmitAccessCode]);


    const getToday = () => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();

        return `${day} / ${month} / ${year}`;
    }

    const submitAccessCode = () => {
        let escape = false
        if (!accessCode || accessCode?.length < 5) {
            escape = true;
            setAccessCodeIsNotGiven(true)
        }

        if (escape) {
            return
        }

        submitAccessCodeMutation(
            {
                code: accessCode,
                patientId: selecetedPatient.id
            }
        )
        setBlockUI(true)
    }

    const getAccessCode = () => {
        setGiftCardIsPresent(false);
        setIntro(false);
        setBlockUI(true)
        getAccessCodeMutation({ patientNewId: selecetedPatient.id })
    }

    const handleAccept = () => {
        setGiftCardIsPresent(true)
        setIntro(false) 
    }

    const goBackTodefault = () => {
        setGiftCardIsPresent(true)
        setIntro(true) 
    }

    return (
        <Dialog
            className="code-dialog"
            visible={codeDialogVisible}
            style={{ width: '25rem', borderRadius: '24px', overflow: "hidden", padding: 0 }}
            onHide={onCodeDialogHide}
            showHeader={false}
        >
            <BlockUI
                blocked={blockUI}
                fullScreen
                style={{ backgroundColor: "transparent" }}
                template={
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
                }
            >
                <div className="flex flex-column">
                    <div className="p-4" style={{
                        background: '#2D3967',
                        minHeight: '8rem',
                        backgroundImage: "url('/codeCardPattern.png')",
                        backgroundRepeat: "no-repeat"
                    }}>
                        <div className="flex justify-content-between align-items-start">
                            <span className="text-2xl font-bold text-white mb-1">{carePlanName}</span>
                            <div className="flex">
                                <Button className="p-button-rounded p-button-text"
                                    style={{ color: 'white', width: "2rem", height: "2rem", display: "flex", justifyContent: "center" }}>
                                    <i className="pi pi-print" />
                                </Button>
                                {!giftCardIsPresent && <Button className="p-button-rounded p-button-text"
                                    style={{ color: 'white', width: "2rem", height: "2rem", display: "flex", justifyContent: "center" }}
                                    onClick={onCodeDialogHide}>
                                    <img src="/cancelWhite.svg" />
                                </Button>}
                            </div>
                        </div>
                        <span className="flex justify-content-end underline" style={{ color: "#9EA4B9" }}>{getToday()}</span>
                    </div>
                    <div className="p-4">
                        <h2 className="text-xl mb-3" style={{ color: '#2D3967' }}>{t("exercises")}</h2>
                        <div className="flex flex-column gap-2" style={{ height: "10rem", overflowY: "auto" }}>
                            {selectedExercises && selectedExercises.map((exercise: any) => (
                                <span className="text-lg" style={{ color: '#9EA4B9' }}>{exercise.exercise.name}</span>
                            ))}
                        </div>
                    </div>
                    <div className="p-4" style={{ background: '#ECFAF5' }}>
                        <div className="flex justify-content-between align-items-center gap-2">
                            <div className="flex flex-column">
                                <p className="m-0 text-sm" style={{ color: "#23CF95" }}>{t("yourPrescription")}</p>
                                <p className="m-0 font-bold text-sm" style={{ color: "#23CF95" }}>{t("createdSuccess")}!</p>
                            </div>
                            <img src="/prescriptionSuccess.svg" />
                        </div>
                    </div>
                    <div className="flex flex-column flex justify-content-center align-items-center accessCode"
                        style={{
                            background: '#2D3967',
                            backgroundImage: "url('/codeCardPattern.png')",
                            backgroundRepeat: "no-repeat",
                            minHeight: '10rem',
                        }}
                    >
                        {intro && <>
                            <p className="m-0 text-md" style={{ color: "white" }}>{t("doYouHaveGiftCard")} ?</p>
                            <div className="flex justify-content-end gap-2 mt-2">
                                <Button
                                    style={{
                                        backgroundColor: "transparent",
                                        width: "7rem",
                                        height: "3rem",
                                        borderRadius: "10px",
                                        border: "1.5px solid white",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "0.5rem"
                                    }}
                                    onClick={getAccessCode}

                                >
                                    <img src="/cancelWhite.svg" />
                                    <p className="m-0 font-bold" style={{ margin: 0 }}>{t("no")}</p>
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#E3506B",
                                        width: "7rem",
                                        height: "3rem",
                                        borderRadius: "10px",
                                        color: "white",
                                        border: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "0.5rem"
                                    }}
                                    onClick={handleAccept}
                                >
                                    <img src="/accept.svg" />
                                    <p className="m-0 font-bold" style={{ margin: 0 }}>{t("yes")}</p>
                                </Button>
                            </div>
                        </>}
                        {!intro && !giftCardIsPresent && <>
                            <p className="m-0 text-md" style={{ color: "white" }}>{t("registerCode")}</p>
                            <p className="m-0 text-4xl font-bold" style={{ color: "#CF4961" }}>{selecetedPatient?.code}</p >

                            <p className="m-0  mt-2 text-sm font-bold" style={{ color: "white" }}>
                                <span style={{ color: "#CF4961" }}>**</span>
                                {t("giveCodeToPatientText")}</p>
                        </>}

                        {!intro && giftCardIsPresent && <>
                            <p className="m-0 mb-2 text-sm" style={{ color: "white" }}>{t("accessCodeOnGiftCard")}</p>
                            <InputOtp value={accessCode} onChange={(e: any) => setAccessCode(e.value)} integerOnly length={5}
                                invalid={accessCodeIsNotGiven ? accessCode ? false : true : false} />
                            <Button
                                className="my-2 w-8"
                                style={{

                                    backgroundColor: "#E3506B",
                                    height: "2.5rem",
                                    borderRadius: "10px",
                                    color: "white",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem"
                                }}
                                onClick={submitAccessCode}
                            >
                                <img src="/accept.svg" />
                                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("submit")}</p>
                            </Button>

                            <p onClick={goBackTodefault} className="m-0 text-sm" style={{ color: "white",cursor:"pointer" }}>{t("noCode")}</p>
                        </>}
                    </div>
                </div>
            </BlockUI>

        </Dialog>
    )
}

export default PatientCodeDialog;