import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


const PatientTable = ({ patientList, lazyParamsForPatients, t }: any) => {
    const navigate = useNavigate();
    const columns = [
        {
            field: "firstName",

        },
        {
            field: "lastName",

        },
        {
            field: "age",

        },
        {
            field: "dateCreated",

        },
        {
            field: "diagnosis",

        },
        {
            field: "action"
        }
    ];

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        age: { value: null, matchMode: FilterMatchMode.EQUALS },
        dateCreated: { value: null, matchMode: FilterMatchMode.DATE_IS },
        diagnosis: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const dateBodyTemplate = (rowData: any) => {
        if (rowData.dateCreated) {
            const formattedDate = rowData.dateCreated.toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
            return formattedDate.replace(", ", " ")
        };
    }

    // const dateFilterTemplate = (options: any) => {
    //     return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
    // };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <p className='text-xl' style={{ color: "#727A99" }}>{t("allPatients")}</p>
                <IconField iconPosition="left" className='w-20rem' >
                    <InputIcon className="pi pi-search" />
                    <InputText className='w-full' style={{ borderRadius: "10px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t("datatableSearchPlaceholder")} />
                </IconField>
            </div>
        );
    };

    const viewPatientDetails = (rowData: any) => {
        localStorage.setItem("patient", JSON.stringify(rowData));
        navigate({ pathname: "/mobithera/prescription" })
    }

    const actionButtons = (rowData: any) => {
        return (
            <div className="flex flex-row flex-wrap ">
                <Button className="m-1" icon={<img src="/viewIcon.svg" />} aria-label="Cancel"
                    onClick={() => viewPatientDetails(rowData)}
                    style={{ backgroundColor: "#F5F5F5", border: "none", borderRadius: "8px", width: "2rem", height: "2rem" }} />

                <Button className="m-1" icon={<img src="/editIcon.svg" />} severity="danger" aria-label="Cancel" size="small"
                    style={{ backgroundColor: "#ECFAF5", border: "none", borderRadius: "8px", width: "2rem", height: "2rem" }} />
            </div>
        )
    }

    const dynamicColumns = columns.map((col: any) => {
        if (col.field === "firstName") {
            return (
                <Column
                    key="firstName"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                />
            );
        }
        else if (col.field === "lastName") {
            return (
                <Column
                    key="lastName"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                />
            );
        } else if (col.field === "age") {
            return (
                <Column
                    key="age"
                    field={col.field}
                    header={t(col.field)}
                    dataType='numeric'
                    sortable
                />
            );
        } else if (col.field === "dateCreated") {
            return (
                <Column
                    key="dateCreated"
                    field={col.field}
                    header={t(col.field)}
                    dataType='date'
                    sortable
                    body={dateBodyTemplate}
                    showApplyButton={false}
                />
            );
        } else if (col.field === "diagnosis") {
            return (
                <Column
                    key="diagnosis"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                    showFilterMatchModes={false}
                    showApplyButton={false}
                />
            );
        } else {
            return (
                <Column
                    key="action"
                    field={col.field}
                    header={t(col.field)}
                    body={actionButtons}
                />
            );
        }
    });

    return (
        <div >
            <DataTable paginator rows={lazyParamsForPatients.rows} value={patientList}
                tableStyle={{ minWidth: '50rem' }} header={header} globalFilterFields={['firstName', 'lastName']}
                filters={filters}>
                {dynamicColumns}
            </DataTable>
        </div>
    )
}

export default PatientTable