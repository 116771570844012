import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { useEffect } from "react";

const PatientDetailCard = ({ t, selectedPatient, planList, tabIndex, setTabIndex, setExerciseDialogVisbible, paitentHistoryDate, setPaitentHistoryDate, painDateOptions }: any) => {

    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";

    const birthDateTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const registerDateTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)

        const options: any = { month: 'long', day: 'numeric' };
        return date.toLocaleDateString(lang, options);
    }

    return (
        <Card style={{ borderRadius: "24px", position: "relative" }}>
            <div className="flex justify-content-between pt-2">
                <p className="m-0 text-xl" style={{ color: "#727A99" }}>{t("patientDetail")}</p>
                {/* <Button
                    style={{
                        backgroundColor: "#E3506B",
                        color: "white",
                        border: "#E3506B",
                        height: "2.5rem",
                        borderRadius: "10px",
                        width: "12rem",
                        fontWeight: "bold"
                    }}
                >
                    <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                        <img src="/editProfileIcon.svg" />
                        {t("editProfile")}
                    </span>
                </Button> */}
            </div>

            <div className="flex flex-wrap justify-content-evenly align-items-center mt-2 gap-4">
                <div className="flex justify-content-center gap-4" >
                    <img src={`${selectedPatient.gender === "Male" ? "/Man.png" : "/Rectangle 5396.png"}`}
                        style={{ borderRadius: "18px", width: "10rem", height: "10rem" }}
                    />

                    <div className="flex flex-column justify-content-center align-items-start">
                        <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{selectedPatient.firstName}</p>
                        <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}> {selectedPatient.lastName}</p>

                        <p className="m-0 text-md mt-2" style={{ color: "#E3506B" }}> {selectedPatient.phoneNumber ? selectedPatient.phoneNumber : "---"}</p>

                        <div className='flex flex-row mt-4 gap-2' style={{ marginLeft: "0.1rem" }}>
                            <img src="/mailIcon.svg" />
                            <span className='text-sm' style={{ color: "#727A99" }}>noMail@gmail.com</span>
                        </div>

                        <div className='flex flex-row mt-2 gap-2'>
                            <img src="/gpsIcon.svg" />
                            <span className='text-sm' style={{ color: "#727A99" }}>Ankara, TR</span>
                        </div>
                    </div>
                </div>

                <div className="ml-2 flex" style={{ overflow: "auto" }}>
                    <div className="h-1" style={{ border: "1px solid #e5e7eb" }}></div>

                    <div className="flex flex-column ">

                        <div className="flex align-items-start">
                            <div className="flex justify-content-center align-items-center px-8 pb-4 gap-4">
                                <img src="/birthDateIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("birthDate")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.birthDate ? birthDateTemplate(selectedPatient) : "---"}</p>
                                </div>
                            </div>
                            <div className="h-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-center align-items-center px-8 pb-4 gap-4">
                                <img src="/registerIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99", whiteSpace: "nowrap" }}>{t("registerDate")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967", whiteSpace: "nowrap" }}>{selectedPatient.dateCreated ? registerDateTemplate(selectedPatient) : "---"}</p>
                                </div>
                            </div>
                            <div className="h-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-center align-items-center px-8 pb-4 gap-4">
                                <img src="/weightIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("weight")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.weight ? selectedPatient.weight : "---"} kg</p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full" style={{ border: "1px solid #e5e7eb" }}></div>

                        <div className="flex align-items-end">
                            <div className="flex justify-content-center align-items-center gap-4 px-8 pt-4">
                                <img src="/genderIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("genderWord")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{t("gender." + selectedPatient.gender)}</p>
                                </div>
                            </div>
                            <div className="h-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-center align-items-center gap-4 px-8 pt-4">
                                <img src="/occupationIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("occupation")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.occupation ? selectedPatient.occupation : "---"}</p>
                                </div>
                            </div>
                            <div className="h-full" style={{ border: "1px solid #e5e7eb" }}></div>
                            <div className="flex justify-content-center align-items-center gap-4 px-8 pt-4">
                                <img src="/heightIcon.svg" />
                                <div className="flex flex-column w-5rem">
                                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("height")}</p>
                                    <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.height ? selectedPatient.height : "---"} sm</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="h-1" style={{ border: "1px solid #e5e7eb" }}></div>
                </div>

                <div className="ml-2 flex flex-column justify-content-center align-items-center">
                    <img src="/registerCodeIcon.svg" />
                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("registerCode")}</p>
                    {(selectedPatient.code && planList?.length > 0)
                        ? <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.code}</p>
                        : <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>---</p>
                    }
                </div>
            </div>
            <div className="w-full mt-4" style={{ border: "1px solid #e5e7eb" }}></div>


            <div className="flex flex-wrap justify-content-between align-items-center mt-2 gap-4">
                <div className="flex flex-wrap justify-content-start ">
                    <Button
                        text
                        onClick={() => setTabIndex(0)}

                        style={{
                            backgroundColor: "white",
                            color: tabIndex === 0 ? "#E3506B" : "#2D3967",
                            marginLeft: "0.1rem",
                        }} >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {tabIndex === 0 ? <img src="/plansAndProgressRedIcon.svg" /> : <img src="/plansAndProgressIcon.svg" />}
                            {t("plansAndProgress")}
                        </span>
                    </Button>
                    <Button
                        text
                        onClick={() => setTabIndex(1)}
                        style={{
                            backgroundColor: "white",
                            color: tabIndex === 1 ? "#E3506B" : "#2D3967",
                            marginLeft: "0.1rem",
                        }} >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {tabIndex === 1 ? <img src="/painInformationRedIcon.svg" /> : <img src="/painInformationIcon.svg" />}
                            {t("patientHistory")}
                        </span>
                    </Button>
                    <Button
                        text
                        onClick={() => setTabIndex(2)}
                        style={{

                            backgroundColor: "white",
                            color: tabIndex === 2 ? "#E3506B" : "#2D3967",
                            marginLeft: "0.1rem",
                        }} >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {tabIndex === 2 ? <img src="/appointmentsRedIcon.svg" /> : <img src="/appointmentsBlueIcon.svg" />}
                            {t("appointments")}
                        </span>
                    </Button>
                </div>

                {tabIndex === 1 &&
                    <Dropdown className="w-12rem" value={paitentHistoryDate} onChange={(e) => setPaitentHistoryDate(e.value)} options={painDateOptions} optionLabel="name" />
                }

                {tabIndex === 0 &&
                    <Button
                        onClick={() => { setExerciseDialogVisbible(true) }}
                        style={{
                            backgroundColor: "#E3506B",
                            color: "white",
                            border: "#E3506B",
                            marginLeft: "0.1rem",
                            height: "2.5rem",
                            borderRadius: "10px",
                            width: "12rem",
                            fontWeight: "bold"
                        }}
                    >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            <img src="/addPatientIcon.svg" />
                            {t("addPlan")}
                        </span>
                    </Button>
                }
            </div>
        </Card>
    )

}

export default PatientDetailCard