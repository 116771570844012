import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useAddPatient } from '../../hooks/useGetDataQuery';
import { useToastContext } from '../../Lib/Contexts/toastContext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { BlockUI } from 'primereact/blockui';
import { countries } from '../countryList';
import { countriesTurkish } from '../countryListTurkish';
import "./addPatientDialog.css";
import { useNavigate } from 'react-router-dom';

const AddPatientDialog = ({ t, setPatientList, addPatientDialogVisible, setAddPatientDialogVisible }: any) => {
    const { showToast } = useToastContext();
    const navigate = useNavigate();

    const [countyList, setCountyList] = useState<any>()

    const [patientName, setPatientName] = useState<any>()
    const [patientSurname, setPatientSurname] = useState<any>()
    const [nationality, setNationality] = useState<any>(undefined)
    const [nationalIdentityNumber, setNationalIdentityNumber] = useState<any>()
    const [blockUI, setBlockUI] = useState(false);

    const genderOptions = ["Male", "Female"];
    const [gender, setGender] = useState(genderOptions[0]);

    const [codeForPhoneNumber, setCodeForPhoneNumber] = useState<any>({ name: 'turkey', code: 'TR', phoneCode: '+90' })
    const [phoneNumber, setPhoneNumber] = useState<any>()

    const [nameIsNotGiven, setNameIsNotGiven] = useState<any>(false)
    const [surnameIsNotGiven, setSurnameIsNotGiven] = useState<any>(false)
    const [nationalityIsNotGiven, setNationalityIsNotGiven] = useState<any>(false)
    const [nationalIdentityNumberIsNotGiven, setNationalIdentityNumberIsNotGiven] = useState<any>(false)
    const [phoneNumberIsNotGiven, setPhoneNumberIsNotGiven] = useState<any>(false)

    const {
        mutate: addPatientMutation,
        data: addPatientMutationData,
        isSuccess: isSuccessAddPatientMutation,
    } = useAddPatient();

    useEffect(() => {
        if (addPatientMutationData) {
            if (addPatientMutationData?.success) {
                const newPatien = {
                    firstName: patientName,
                    lastName: patientSurname,
                    dateCreated: new Date(),
                    nationality: nationality.code,
                    nationalIdentityNumber: nationalIdentityNumber,
                    phoneNumber: phoneNumber ? codeForPhoneNumber.phoneCode + "" + phoneNumber : undefined,
                    gender: gender,
                    isActive: true,
                    id: addPatientMutationData.data.id,
                }

                setPatientList((prevList: any) => prevList ? [...prevList, newPatien] : [newPatien])
                onaddPatientDialogHide()
                setBlockUI(false)
                localStorage.setItem("patient", JSON.stringify(newPatien));
                navigate({ pathname: "/mobithera/prescription" })
            } else {
                setBlockUI(false)
                onaddPatientDialogHide()
                showToast({ severity: 'error', summary: 'Error', detail: addPatientMutationData.message });
            }
        }
    }, [isSuccessAddPatientMutation]);

    useEffect(() => {
        if (addPatientDialogVisible) {
            localStorage.getItem("i18nextLng") === "en"
                ? setCountyList(countries)
                : setCountyList(countriesTurkish)
            localStorage.getItem("i18nextLng") === "en"
                ? setCodeForPhoneNumber({ name: 'turkey', code: 'TR', phoneCode: '+90' })
                : setCodeForPhoneNumber({ name: 'türkiye', code: 'TR', phoneCode: '+90' })
        }

    }, [addPatientDialogVisible]);

    const onaddPatientDialogHide = () => {
        addPatientDefault()
        setAddPatientDialogVisible(false)
    }

    const onSavePatientDialog = () => {
        let escape = false
        if (!patientName) {
            escape = true;
            setNameIsNotGiven(true)
        }
        if (!patientSurname) {
            escape = true;
            setSurnameIsNotGiven(true)
        }
        if (!nationality) {
            escape = true;
            setNationalityIsNotGiven(true)
        }
        if (!nationalIdentityNumber) {
            escape = true;
            setNationalIdentityNumberIsNotGiven(true)
        }

        if (!phoneNumber) {
            escape = true;
            setPhoneNumberIsNotGiven(true)
        }

        if (escape) {
            return
        }

        const newPatien = {
            firstName: patientName,
            lastName: patientSurname,
            dateCreated: new Date(),
            nationality: nationality.code,
            nationalIdentityNumber: nationalIdentityNumber,
            phoneNumber: phoneNumber ? codeForPhoneNumber.phoneCode + "" + phoneNumber : undefined,
            gender: gender,
            isActive: true
        }

        setBlockUI(true)
        addPatientMutation(newPatien)
    }

    const addPatientDefault = () => {
        setPatientName(undefined);
        setPatientSurname(undefined);
        setNationality(undefined)
        setNationalIdentityNumber(undefined);
        setPhoneNumber(undefined);
        setCodeForPhoneNumber(undefined);
        setGender(genderOptions[0])

        setNameIsNotGiven(false);
        setSurnameIsNotGiven(false);
        setNameIsNotGiven(false)
        setNationalityIsNotGiven(false)
        setNationalIdentityNumberIsNotGiven(false)
        setPhoneNumberIsNotGiven(false)
    }

    const toTitleCase = (str: any) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                    <div>{toTitleCase(option.name)}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const selectedCountryTemplateForPhone = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center p-1">
                    <img alt={option.name}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={t(option.name)} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                <div>{toTitleCase(option.name)}</div>
            </div>
        );
    };

    const countryOptionTemplateForPhone = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                <div>{toTitleCase(option.name)}</div>
                <div className='ml-2'>{option.phoneCode}</div>
            </div>
        );
    };

    const headerContent = (
        <p className="m-0 text-md" style={{ color: "#727A99" }}>{t('newPatient')}</p>
    );

    const footerContent = (
        <div className="flex justify-content-end gap-2">
            <Button
                style={{
                    backgroundColor: "white",
                    width: "10rem",
                    height: "3rem",
                    borderRadius: "10px",
                    border: "1.5px solid #2D3967",
                    color: "#2D3967",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem"
                }}
                onClick={onaddPatientDialogHide}
            >
                <img src="/cancel.svg" />
                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("cancel")}</p>
            </Button>
            <Button
                style={{
                    backgroundColor: "#E3506B",
                    width: "14rem",
                    height: "3rem",
                    borderRadius: "10px",
                    color: "white",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem"
                }}
                onClick={onSavePatientDialog}
            >
                <img src="/accept.svg" />
                <p className="m-0 font-bold" style={{ margin: 0 }}>{t("savePatient")}</p>
            </Button>
        </div>
    );

    const genderTemplate = (option: any) => {
        return <span>{t(`gender.${option}`)}</span>
    }

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            <Dialog footer={footerContent} header={headerContent}
                visible={addPatientDialogVisible}
                style={{ width: '45vw', borderRadius: '24px', overflow: "hidden" }}
                onHide={onaddPatientDialogHide} blockScroll>
                <div className="flex flex-column flex-wrap gap-4 justify-content-center p-2">
                    <div className="flex w-full" >
                        <Button className='w-full' style={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                            border: "1.5px solid #2D3967",
                            color: gender === genderOptions[0] ? "white" : "#2D3967",
                            backgroundColor: gender === genderOptions[0] ? "#2D3967" : "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1rem"
                        }}
                            onClick={() => setGender(genderOptions[0])}
                        >
                            <p className="m-0 font-bold text-lg" style={{ margin: 0 }}>{genderTemplate(genderOptions[0])}</p>
                            <img src={gender === genderOptions[0] ? "/maleIconWhite.svg" : "/maleIconBlue.svg"} />
                        </Button >
                        <Button className='w-full'
                            style={{
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                                border: "1.5px solid #2D3967",
                                color: gender === genderOptions[1] ? "white" : "#2D3967",
                                backgroundColor: gender === genderOptions[1] ? "#2D3967" : "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "1rem"
                            }}
                            onClick={() => setGender(genderOptions[1])}
                        >
                            <p className="m-0 font-bold text-lg" style={{ margin: 0 }}>{genderTemplate(genderOptions[1])}</p>
                            <img src={gender === genderOptions[1] ? "/femaleIconWhite.svg" : "/femaleIconBlue.svg"} />
                        </Button >

                    </div>

                    <div className='flex w-full gap-4'>
                        <div className="flex w-full flex-column gap-2">
                            <label>{t("patientName")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                            <InputText value={patientName}
                                style={{ borderRadius: "10px" }}
                                className={nameIsNotGiven ? patientName ? undefined : "p-invalid mr-2" : undefined}
                                onChange={e => setPatientName(e.target.value)} />
                            {!patientName && nameIsNotGiven && <Message severity="error" text={t("nameIsRequired")} />}
                        </div>

                        <div className="flex w-full flex-column gap-2" >
                            <label>{t("patientSurname")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                            <InputText value={patientSurname}
                                style={{ borderRadius: "10px" }}
                                className={surnameIsNotGiven ? patientSurname ? undefined : "p-invalid mr-2" : undefined}
                                onChange={e => setPatientSurname(e.target.value)} />
                            {!patientSurname && surnameIsNotGiven && <Message severity="error" text={t("surnameIsRequired")} />}
                        </div>
                    </div>


                    <div className="flex flex-column gap-2" >
                        <label htmlFor="nationality">{t("nationality")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                        <Dropdown value={nationality} onChange={(e) => setNationality(e.value)} options={countyList} optionLabel="name"
                            valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} showClear
                            className={nationalityIsNotGiven ? nationality ? 'w-full' : "p-invalid mr-2 w-full" : 'w-full'} style={{ height: "3rem", borderRadius: "10px" }} />
                        {!nationality && nationalityIsNotGiven && <Message severity="error" text={t("nationalityIsRequired")} />}
                    </div>

                    <div className="flex flex-column gap-2" >
                        <label htmlFor="nationalIdentityNumber">{t("nationalIdentityNumber")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                        <InputText id="nationalIdentityNumber" value={nationalIdentityNumber}
                            className={nationalIdentityNumberIsNotGiven ? nationalIdentityNumber ? undefined : "p-invalid mr-2" : undefined}
                            style={{ borderRadius: "10px" }}
                            onChange={e => setNationalIdentityNumber(e.target.value)} />
                        {!nationalIdentityNumber && nationalIdentityNumberIsNotGiven && <Message severity="error" text={t("nationalIdentityNumberIsRequired")} />}
                    </div>

                    <div className="flex flex-column gap-2 addPatient">
                        <label htmlFor="phone">{t("phoneNumber")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                        <div className="combo-box flex align-content-center align-items-center ">
                            <Dropdown style={{ height: '3rem', width: '6rem' }} value={codeForPhoneNumber} options={countyList} optionLabel="name"
                                valueTemplate={selectedCountryTemplateForPhone} itemTemplate={countryOptionTemplateForPhone}
                                onChange={e => setCodeForPhoneNumber(e.target.value)} />
                            <InputNumber className={phoneNumberIsNotGiven ? phoneNumber ? "w-full" : "p-invalid mr-2 w-full" : "w-full"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.value)} useGrouping={false} style={{ height: "3rem" }} />
                        </div>
                        {!phoneNumber && phoneNumberIsNotGiven && <Message severity="error" text={t("phoneNumberIsReuqired")} />}
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    )
}

export default AddPatientDialog;